import type { ISeoTemplateVariablesFlattenPartial, ISeoTemplateVariablesKeys } from '~/shared/types/composables/seo'
import type { IApiContentVideoItem } from '~/api/content/videos'
import { getSeoAggregatesVideo } from "~/shared/constants/aggregates/video"

export function getSeoAggregatesGame(
  context: ISeoTemplateVariablesFlattenPartial,
  variable: ISeoTemplateVariablesKeys,
  item: IApiContentVideoItem,
) {
  if (!item) {
    return ''
  }
  if ('_skeleton' in item) {
    return '_skeleton'
  }

  switch (variable) {
    case 'entity_name':
    case 'game_card_name':
      return item.name
    case 'game_card_address':
      return `${context.site_address}${item.slug}/`
    case 'game_card_preview_image_address':
      return item.previewImage?.path
    case 'game_card_studio_name':
      return item.studio.name
    case 'video_card_date_published':
      const date = new Date(item?.createdAt * 1000)
      return date.toISOString()
    case 'video_card_studio_address':
      return `${context.site_address}studio/${item.studio.slug}/`
    case 'video_card_studio_image':
      return item.studio?.logo?.path || ''
    case 'models_name':
      return item?.models?.join(', ') || ''
    default:
      if (context[variable]) {
        return context[variable]
      }
      return `{var ${variable}: not define}`
  }
}
