import { useUserStore } from '~/stores/user'
import apiContentResultVerification from "~/api/content/result-verification"
import apiContentVerification from "~/api/content/verification"
import { SOFT_PAGE_COOKIE_OPTIONS, VERIFIED_COOKIE_OPTIONS } from "~/shared/constants/soft-page"

export default defineNuxtRouteMiddleware(async(to) => {
  const nvToken = useState<boolean>('nv-token')
  const cookieSoftPage = useCookie<boolean>('soft-page', SOFT_PAGE_COOKIE_OPTIONS)
  const cookieVerified = useCookie<string>('av_clearance', VERIFIED_COOKIE_OPTIONS)
  const ageCookie = useCookie<boolean>('age')

  if (!nvToken.value) {
    cookieSoftPage.value = false
    return
  }

  const userStore = useUserStore()

  if(!cookieVerified.value && userStore.isAuthorized) {
    const resultVerification = await apiContentResultVerification({ uuid: userStore.user?.jti })
    if(resultVerification?.data?.isUserHasVerification) {
      cookieVerified.value = 'WVdkbElIWmxjbWxtYVdOaGRHbHZiaUJ3WVhOelpXUT0='
    }
  }
  const isVerified = userStore.isAuthorized && cookieVerified.value

  const isRedirectFromYoti = () => {
    const cookieSessionId = useCookie<string>('sessionId')
    return (
      userStore.isStatus.active &&
      to.name === 'age-verification' &&
      to.query?.sessionId &&
      to.query.sessionId === cookieSessionId.value
    )
  }

  ageCookie.value = true

  if (userStore.isRole.superAdmin || userStore.isRole.admin || userStore.isRole.moderator || isVerified) {
    cookieSoftPage.value = false
    if (to.name === 'age-verification') {
      return navigateTo({ path: '/' }, { redirectCode: 301 })
    }

    return
  }
  cookieSoftPage.value = true

  const pagesEnable = [
    '/privacy-policy/',
    '/terms-of-service/',
    '/ca-privacy-rights/',
    '/complaints-policy/',
    '/2257-statement/',
    '/dmca/',
    '/',
    '/account/setting/',
    '/premiumlp/',
    '/callback/',
    '/age-verification/',
    '/login/',
    '/contact/',
    '/confirm-email/',
  ]

  let redirectPath = '/'

  try {
    switch (true) {
      case isRedirectFromYoti():
        await apiContentVerification({
          uuid: userStore.user?.jti,
          isVerified: true,
        })
        cookieVerified.value = 'WVdkbElIWmxjbWxtYVdOaGRHbHZiaUJ3WVhOelpXUT0='
        return navigateTo({ path: '/' }, { redirectCode: 301 })
      case userStore.isStatus.active && !(to.name === 'age-verification'):
        return navigateTo({ path: '/age-verification/' }, { redirectCode: 301 })
      case !userStore.isStatus.expired && !userStore.isStatus.inactive:
        pagesEnable.push('/login/')
    }
  } catch (e) {
    useConsole().warn('verifiedMember.global', 'isRedirectFromYoti')
  }

  if (pagesEnable.includes(to.path)) {
    return
  }

  return navigateTo({ path: redirectPath }, { redirectCode: 301 })
})
