/**
 * @method POST
 * @description подтверждение почты & авторизация (возвращает токены для авторизации)
 * */

export interface IApiAuthConfirmEmailProps {
  token: string
}

export default async function apiAuthConfirmEmail(payload: IApiAuthConfirmEmailProps) {
  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.post(pathUser + '/wp/auth/confirmEmail', payload)
  })
}
