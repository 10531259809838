/**
 * @method POST
 * @description повторно отправить ссылку на подтверждение почты
 * @timeout 10min
 * */

export interface IApiAuthConfirmEmailResendProps {
  email: string
}

export default async function apiAuthConfirmEmailResend(payload: IApiAuthConfirmEmailResendProps) {
  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.post(pathUser + '/wp/auth/confirmEmail/resend', payload)
  })
}
