/**
 * @method POST
 * @description логин
 * */

export interface IApiAuthLoginProps {
  name?: string
  email?: string
  password: string
}

export default async function apiAuthLogin(payload: IApiAuthLoginProps) {
  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.post(pathUser + '/wp/auth/login', payload)
  })
}
