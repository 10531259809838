import type { CookieOptions } from "#app"

export const JS_HIT_COOKIE = 'vrbcash_js_hit_s'
export const AFF_ID_COOKIE = 'aff_id'
export const OFFER_ID_COOKIE = 'offer_id'
export const JS_HIT_COOKIE_OPTIONS = {
  default: () => false,
  path: '/',
  maxAge: 60 * 60 * 24 * 7, // 7 days
  readonly: false,
} as (CookieOptions<boolean>)
