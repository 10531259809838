/**
 * @method { GET | PUT }
 * @description получение и изменение настроек нотификаций пользователя
 * */

export interface IApiContentNotificationSettingsItems {
  settings: {
    isNotifyCams: boolean
    isNotifyGame: boolean
    isNotifyNews: boolean
    isNotifyModel: boolean
    isNotifyVideo: boolean
    isNotifyLikesAndComments: boolean
  }
}

export interface IApiContentNotificationSettingsPayload {
  settings: {
    isNotifyCams: boolean
    isNotifyGame: boolean
    isNotifyNews: boolean
    isNotifyModel: boolean
    isNotifyVideo: boolean
    isNotifyLikesAndComments: boolean
    marketing: boolean
  }
}

export async function apiContentNotificationSettings() {
  const endpoint = `/account/notification/settings`

  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.get(pathUser + endpoint)
  })
}

export async function apiContentNotificationSettingsUpdate(payload: IApiContentNotificationSettingsPayload) {
  const endpoint = `/account/notification/settings`

  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.put(pathUser + endpoint, payload)
  })
}
