import revive_payload_client_4sVQNw7RlN from "/var/www/vrp_content_front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/vrp_content_front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/vrp_content_front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/vrp_content_front/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/vrp_content_front/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/vrp_content_front/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/vrp_content_front/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/vrp_content_front/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/vrp_content_front/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/vrp_content_front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/var/www/vrp_content_front/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import _1_initialization_XZFHArSOJb from "/var/www/vrp_content_front/plugins/1.initialization.ts";
import _2_eventBus_ccODFo0RY4 from "/var/www/vrp_content_front/plugins/2.eventBus.ts";
import _3_axiosInstance_g75rpc7uSd from "/var/www/vrp_content_front/plugins/3.axiosInstance.ts";
import _4_socket_client_X9bnT1aD8B from "/var/www/vrp_content_front/plugins/4.socket.client.ts";
import _5_datePicker_fdMqBRkce2 from "/var/www/vrp_content_front/plugins/5.datePicker.ts";
import _6_reCaptchaV3_client_hc4kUNUUmL from "/var/www/vrp_content_front/plugins/6.reCaptchaV3.client.ts";
import click_outside_D2NiLGp7dJ from "/var/www/vrp_content_front/plugins/click-outside.ts";
import emoji_textarea_client_fqiSW6fTE2 from "/var/www/vrp_content_front/plugins/emoji-textarea.client.ts";
import nv_token_TizT3rbYzB from "/var/www/vrp_content_front/plugins/nv-token.ts";
import toast_ysMjUcU7eJ from "/var/www/vrp_content_front/plugins/toast.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  _1_initialization_XZFHArSOJb,
  _2_eventBus_ccODFo0RY4,
  _3_axiosInstance_g75rpc7uSd,
  _4_socket_client_X9bnT1aD8B,
  _5_datePicker_fdMqBRkce2,
  _6_reCaptchaV3_client_hc4kUNUUmL,
  click_outside_D2NiLGp7dJ,
  emoji_textarea_client_fqiSW6fTE2,
  nv_token_TizT3rbYzB,
  toast_ysMjUcU7eJ
]