<template>
  <section class="app-page-not-found mb2-40 mb2-24_table" :class="{'app-router-provider--expanded': isExpanded}" data-testid="elem_vrp_000283">
    <div class="app-page-not-found__oops" data-testid="elem_vrp_000284">
      <NotFoundIcon class="app-page-not-found__logo" data-testid="elem_vrp_000285" />
      <div class="app-page-not-found__message" data-testid="elem_vrp_000286">
        <h1 class="app-page-not-found__title" data-testid="elem_vrp_000287">
          {{ seo.heading.value }}
        </h1>
        <p class="app-page-not-found__subtitle" data-testid="elem_vrp_000288">
          <span data-testid="elem_vrp_000397">Sorry... Requested page doesn't exist. Go to the</span>
          <NuxtLink class="app-page-not-found__link stl-link-blue" :to="{ path: '/' }" data-testid="elem_vrp_000289">
            homepage
          </NuxtLink>
        </p>
      </div>
    </div>
    <div class="app-page-not-found__content" data-testid="elem_vrp_000290">
      <h2 class="app-page-not-found__content-title stl-color-white mt2-0 mb2-0" data-testid="elem_vrp_000291">
        Suggested Videos
      </h2>
      <div class="content-block-list content-block-list_mini mt2-12" data-testid="elem_vrp_000292">
        <template v-if="videosFromError.length">
          <video-card v-for="(video, i) in videosFromError" :key="i" :video="video" :playing-video-id="touchedVideoId" @touchstart="setTouchedVideoId(video)" />
        </template>
        <template v-else>
          <ui-card-video-card-skeleton v-for="i in new Array(12)" :key="i" :strait="false" />
        </template>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import VideoCard from '~/components/ui/card/video-card/video-card.vue'
import NotFoundIcon from '~/public/svg/not-found.svg'
import apiContentVideosHome from '~/api/content/videos-home'
import { SEO_ENTITY_PAGE_TYPE } from '~/shared/constants/seo'
import type { IApiContentVideoItem } from "~/api/content/videos";

interface Props {
  preventThrow?: boolean
  isExpanded?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  preventThrow: false,
})

const touchedVideoId = ref('')
const setTouchedVideoId = (card:IApiContentVideoItem) => {
  touchedVideoId.value = card.id
}

const {
  preventThrow,
} = toRefs(props)

if (!preventThrow.value) {
  throw createError({ statusCode: 404, statusMessage: 'NoPreventThrow' })
}

const seo = useSeoModule()

const videosFromError = useState('videos-from-error', () => ([]))
const loadVideosFromError = async () => {
  await callOnce('videos-from-error', async () => {
    const response = await apiContentVideosHome()
    if (response?.data?.hotRightNow?.length) {
      videosFromError.value = response.data.hotRightNow
    }
  })
}

useClientOnly(async () => {
  await loadVideosFromError()
})

const config = seo.defineSeoPage(SEO_ENTITY_PAGE_TYPE.root404, {
  canonicalPath: '/404/',
  data: {
    entity_breadcrumb: 'Not Found',
  },
})

seo.applySeoForPage(config)
</script>
