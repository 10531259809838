/**
 * @method GET
 * @description Retrieve a digest with public data
 * */

import type { IApiContentDigestResponse } from "~/shared/types/digest/digest"

export default async function apiContentDigest(): Promise<IApiContentDigestResponse> {
  const endpoint = `/content/v1/digest`

  return await useAsyncQuery(async ({ axios, path }) => {
    return await axios.get(path + endpoint)
  })
}
