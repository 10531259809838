/**
 * @method POST
 * @description обновить токен
 * */

interface IApiAuthRefreshProps {
  refresh_token: string
}

export default async function apiAuthRefresh(payload: IApiAuthRefreshProps) {
  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.post(pathUser + `/v1/auth/refresh-token?refresh_token=${payload.refresh_token}`)
  })
}
