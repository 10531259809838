/**
 * @method GET
 * @description получаем блоки в видео для главной страницы
 * */

import type { IApiContentVideoItem } from '~/api/content/videos'

export interface IApiContentVideosHomeResponse {
  new?: IApiContentVideoItem[]
  hotRightNow?: IApiContentVideoItem[]
  popular?: IApiContentVideoItem[]
  game?: IApiContentVideoItem[]
}

export default async function apiContentVideosHome () {
  const endpoint = `/content/v1/videos/home`

  return await useAsyncQuery(async ({ axios, path }) => {
    return await axios.get(path + endpoint)
  })
}
