import type { CookieOptions } from "#app"

export const SOFT_PAGE_COOKIE_OPTIONS = {
  default: () => false,
  path: '/',
  maxAge: 60 * 60 * 24 * 30,
  readonly: false,
} as (CookieOptions<boolean>)

export const VERIFIED_COOKIE_OPTIONS = {
  default: () => false,
  path: '/',
  maxAge: 60 * 60 * 24 * 365,
  readonly: false,
} as (CookieOptions<boolean>)
