import axios from 'axios'

export default defineNuxtPlugin({
  name: 'app:axios-instance',

  async setup() {
    const axiosInstance = axios.create()
    const isAxiosInit = ref(false)
    const noRetry$ = ref(false)
    const basicHeaders = {
      Accept: 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    }

    return {
      provide: {
        axiosPlugin: {
          axiosInstance,
          isAxiosInit,
          noRetry$,
          basicHeaders,
        },
      },
    }
  },
})
