/**
 * @method POST
 * @description confirm-change-email-action
 * */

export interface IApiAuthConfirmChangeEmailActionProps {
  email: string // new
  token: string
}

export interface IApiAuthConfirmChangeEmailActionResponse {

}

export default async function apiAuthConfirmChangeEmailAction(payload: IApiAuthConfirmChangeEmailActionProps) {
  const endpoint = '/user/confirm-change-email-action'

  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return axios.post(pathUser + endpoint, payload)
  })
}
