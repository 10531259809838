import mergeWith from 'lodash-es/mergeWith'
import cloneDeep from 'lodash-es/cloneDeep'
import type { UseHeadInput } from 'unhead'
import type { IApiContentVideoItem } from '~/api/content/videos'
import type { IApiContentStudiosListItem } from '~/api/content/studios-list'
import type { IApiContentModelsItem } from '~/api/content/models'
import type { IApiContentCategoriesItem } from '~/api/content/categories'
import type { IApiContentSeoPagesItem } from '~/api/content/seo-page'
import type {
  ISeoTemplateVariablesComposite, ISeoTemplateVariablesFlatten,
  ISeoTemplateVariablesFlattenPartial, ISeoTemplateVariablesKeys,
} from '~/shared/types/composables/seo'
import { getSeoAggregatesVideo } from '~/shared/constants/aggregates/video'
import { getSeoAggregatesGame } from '~/shared/constants/aggregates/game'
import { getSeoAggregatesModel } from '~/shared/constants/aggregates/model'
import { getSeoAggregatesCategory } from '~/shared/constants/aggregates/category'
import { getSeoAggregatesStudio } from '~/shared/constants/aggregates/studio'
import { useSeoStore } from '~/stores/seo'
import { useGlobalStore } from '~/stores'
import { SEO_ENTITY_PAGE_TYPE, SEO_ENTITY_PAGE_MOCK } from '~/shared/constants/seo'
import { EnumSeoAltTitlesSlug } from '~/api/content/seo-alt-titles-slug'

type TAggregatesGen = { [K in keyof ISeoTemplateVariablesFlatten]: (variable: ISeoTemplateVariablesKeys, item: object) => string | null | undefined }

type TDefineSeoPageOptions = {
  canonicalPath: string
  data?: ISeoTemplateVariablesFlattenPartial
  seoParams?: IApiContentSeoPagesItem | null
}

interface ISeoState {
  useHead: UseHeadInput<{}>
}

const nestedKeysWithAndOperation = ['isIncludeInSiteMap', 'isJsonScriptLd', 'isOG', 'isTwitterParams']

const compareParams = (value: any, srcValue: any, key: string, object2: any, object: any) => {
  if (typeof srcValue === 'object' && Array.isArray(srcValue)) {
    // compare fields: blocksJsonLd, contentJsonLd
    object[key] = srcValue.map((item, index) => {
      return item || value[index]
    })
  } else if (typeof srcValue === 'object' && srcValue) {
    // compare fields: ogParams, twitterParams
    // TODO надо фиксить .!.
    if(object?.name && object2?.name) {
      object[key] = mergeWith(srcValue, value, compareParams)
    } else {
      object[key] = mergeWith(value, srcValue, compareParams)
    }
  } else if (typeof srcValue === 'boolean') {
    // compare nested-support boolean fields
    if (nestedKeysWithAndOperation.includes(key)) {
      object[key] = value && srcValue
    } else {
      object[key] = srcValue
    }
  } else if (srcValue) {
    // compare other fields
    object[key] = srcValue
  } else {
    object[key] = value === null ? '' : value
  }

  return object[key]
}

export function useSeoModule() {
  const nuxtApp = useNuxtApp()
  const router = useRouter()
  const globalStore = useGlobalStore()
  const seoStore = useSeoStore()

  const state = useState<ISeoState>('seo', () => {
    return {
      useHead: {
        title: '',
        meta: [],
        scripts: [],
      },
    }
  })

  const flatTemplates = useState<ISeoTemplateVariablesFlattenPartial>('seoTemplates', () => ({}))
  const flatContext = useState<ISeoTemplateVariablesFlattenPartial>('seoContext', () => ({}))
  const heading = useState(`seoHeading:${router.currentRoute.value.name}`, () => '')
  const canonicalDefault = useState('seoCanonicalDefault', () => '')
  const breadcrumbDefault = useState('seoBreadcrumbDefault', () => '')

  const isRenderClientFlag = router.currentRoute.value.query.seo_uwu !== undefined

  const createOverrideContext = (overrideContext: ISeoTemplateVariablesFlattenPartial = {}) => {
    return overrideContext
  }

  const createImageTitle = (title: string | unknown, overrideContext: ISeoTemplateVariablesFlattenPartial = {}) => {
    if (typeof title !== 'string') {
      return ''
    }
    return prepareSeoTemplateByVariable(title, flatContext.value, overrideContext).text
  }

  const createImageAlt = (alt: string | unknown, overrideContext: ISeoTemplateVariablesFlattenPartial = {}) => {
    if (typeof alt !== 'string') {
      return ''
    }
    return prepareSeoTemplateByVariable(alt, flatContext.value, overrideContext).text
  }

  const aggregates: TAggregatesGen = {
    /** @description Должна собрать массив из всех карточкек видео, находящихся в блоке New и описать каждую из них */
    main_new_videos_block: (variable, item) => {
      return getSeoAggregatesVideo(flatContext.value, variable, item as IApiContentVideoItem)
    },

    /** @description Должна собрать массив из всех карточкек видео, находящихся в блоке Hot Right Now и описать каждую из них */
    main_hot_videos_block: (variable, item) => {
      return getSeoAggregatesVideo(flatContext.value, variable, item as IApiContentVideoItem)
    },

    /** @description Должна собрать массив из всех карточкек видео, находящихся в блоке Games и описать каждую из них */
    main_games_block: (variable, item) => {
      return getSeoAggregatesGame(flatContext.value, variable, item as IApiContentVideoItem)
    },

    /** @description Должна собрать массив из всех карточкек видео, находящихся в блоке Popular и описать каждую из них */
    main_popular_videos_block: (variable, item) => {
      return getSeoAggregatesVideo(flatContext.value, variable, item as IApiContentVideoItem)
    },

    /** @description Должна собрать массив из всех карточкек игр, выводящихся на конкретную страницу категории и описать каждую из них */
    category_game_block: (variable, item) => {
      return getSeoAggregatesGame(flatContext.value, variable, item as IApiContentVideoItem)
    },

    /** @description Должна собрать массив из всех карточкек игр, выводящихся на конкретную страницу категории и описать каждую из них */
    category_videos_block: (variable, item) => {
      return getSeoAggregatesVideo(flatContext.value, variable, item as IApiContentVideoItem)
    },

    videos_block: (variable, item) => {
      return getSeoAggregatesVideo(flatContext.value, variable, item as IApiContentVideoItem)
    },

    /** @description Должна собрать массив из всех карточкек моделей, добавленных к видео и описать каждую из них */
    video_model_block: (variable, item) => {
      return getSeoAggregatesModel(flatContext.value, variable, item as IApiContentModelsItem)
    },

    /** @description Должна собрать массив из всех категорий, к котороым прикреплено видео и описать каждую из них */
    video_category_block: (variable, item) => {
      return getSeoAggregatesCategory(flatContext.value, variable, item as IApiContentCategoriesItem)
    },

    /** @description Должна собрать массив из всех категорий, к котороым прикреплена игра и описать каждую из них */
    game_category_block: (variable, item) => {
      return getSeoAggregatesCategory(flatContext.value, variable, item as IApiContentCategoriesItem)
    },

    /** @description Должна собрать массив из всех видео в видео которых модель снималась и описать каждое из них */
    model_video_block: (variable, item) => {
      return getSeoAggregatesVideo(flatContext.value, variable, item as IApiContentVideoItem)
    },

    /** @description Должна собрать массив из всех студий в видео которых модель снималась и описать каждую из них */
    model_affilation_block: (variable, item) => {
      return getSeoAggregatesStudio(flatContext.value, variable, item as IApiContentStudiosListItem)
    },

    /** @description Должна собрать массив из всех карточкек видео, выводящихся на конкретную страницу студии и описать каждую из них */
    studio_videos_block: (variable, item) => {
      return getSeoAggregatesVideo(flatContext.value, variable, item as IApiContentVideoItem)
    },

    /** @description xxx */
    studio_game_block: (variable, item) => {
      return getSeoAggregatesGame(flatContext.value, variable, item as IApiContentVideoItem)
    },

    /** @description Текстовое содержание статьи */
    article_body_block: (variable, item) => {
      return item as unknown as string
    },
  }

  const generateCompositeContextByItems = (
    key: keyof ISeoTemplateVariablesComposite,
    items: unknown[],
    overrideContext: ISeoTemplateVariablesFlattenPartial = {}
  ) => {
    if (!Array.isArray(items)) {
      items = []
    }

    if (import.meta.client && !isRenderClientFlag) {
      return null
    }

    const schemeItems = flatTemplates.value[key] || overrideContext[key] || ''

    const preparedItems: string[] = []

    items.forEach((item: unknown) => {
      const payload = schemeItems.replace(/%(.*?)%/g, (match, pass) => {
        const variable = pass.trim() as string as ISeoTemplateVariablesKeys

        if (!variable) {
          return ''
        }

        if (typeof aggregates[key] !== 'function') {
          useConsole().error('useSeoModule', `Please, define aggregate key: ${key}`)
        }
        const value = aggregates[key](variable, item as object)
        if (!value) {
          return ''
        }
        return value
      })

      try {
        preparedItems.push(JSON.parse(payload))
      } catch (e) {
        useConsole().error('useSeoModule', 'Compound variable must be a valid object', e)
      }
    })

    setContextDataByKey(key, preparedItems)
  }

  const setTemplateDataByKey = (
    key: keyof ISeoTemplateVariablesFlatten,
    value: unknown
  ) => {
    if (import.meta.client && !isRenderClientFlag) {
      return
    }
    if (value) {
      flatTemplates.value[key] = value as string
    }
  }

  const setContextDataByKey = (
    key: keyof ISeoTemplateVariablesFlatten,
    value: unknown
  ) => {
    flatContext.value[key] = value as string
  }

  const setDefaultContext = () => {
    // page
    const nestedItem = seoStore.pagesData.find((item) => item.name === SEO_ENTITY_PAGE_TYPE.page)

    setContextDataByKey('site_name', globalStore.siteSettings?.siteName)
    setContextDataByKey('site_address', `${nuxtApp.$config.public.BASE_URL}/`)
    setContextDataByKey('site_host', `${nuxtApp.$config.public.BASE_URL}`)

    setContextDataByKey('site_title', nestedItem?.title)
    setContextDataByKey('site_heading', nestedItem?.heading)
    setContextDataByKey('site_meta_description', nestedItem?.metaDescription)
    setContextDataByKey('site_og_image', globalStore.siteSettings?.headerLogoLight?.permalink)

    setContextDataByKey('entity_name', flatContext.value?.site_name)
    setContextDataByKey('entity_title', flatContext.value?.site_title)
    setContextDataByKey('entity_meta_description', flatContext.value?.site_meta_description)

    setContextDataByKey('entity_heading', flatContext.value?.site_heading)

    const datePublishedObject = new Date()
    datePublishedObject.setFullYear(2024, 10, 25)
    const dateModifiedObject = new Date()
    dateModifiedObject.setFullYear(2024, 10, 25)
    const datePublished = datePublishedObject.toISOString()
    const dateModified = dateModifiedObject.toISOString()
    setContextDataByKey('entity_date_published', datePublished)
    setContextDataByKey('entity_date_modified', dateModified)

    canonicalDefault.value = `${nuxtApp.$config.public.BASE_URL}/`
    breadcrumbDefault.value = nestedItem?.breadcrumb || globalStore.siteSettings?.siteName
  }

  const defineSeoPage = (
    identifier: SEO_ENTITY_PAGE_TYPE,
    {
      canonicalPath = '',
      data = {},
      seoParams = null,
    }: TDefineSeoPageOptions
  ) => {
    setDefaultContext()

    let commonNestedPage = cloneDeep(
      seoStore.pagesData.find(
        (item) => item.name === SEO_ENTITY_PAGE_TYPE.page
      ) as IApiContentSeoPagesItem
    )

    let page = cloneDeep(
      seoStore.pagesData.find((
        item) => item.name === identifier
      ) as IApiContentSeoPagesItem
    )
    if (identifier === SEO_ENTITY_PAGE_TYPE.staticPage && seoParams?.title) {
      page = cloneDeep(seoParams)
      page.id = 'static page'
    }

    if (!page?.id) {
      return {
        canonicalUrl: useCanonicalUrl(),
        data: {},
        page: SEO_ENTITY_PAGE_MOCK,
        singleSeoParams: {},
      }
    }

    let source;

    if (!seoParams) {
      source = cloneDeep(commonNestedPage)
      seoParams = cloneDeep(page)
    } else {
      source = page
    }

    if (seoParams.isOG && seoParams.ogParams?.type === null) {
      seoParams.ogParams = commonNestedPage?.ogParams
    }
    if (seoParams.isOG && !seoParams.ogParams) {
      seoParams.ogParams = {
        type: 'website',
        title: '',
        description: '',
        url: '',
        siteName: '',
      }
    }

    const pageWithNestedData = mergeWith(source, seoParams ? cloneDeep(seoParams) : {}, compareParams)

    if (!pageWithNestedData?.name) {
      createError({ status: 404, statusText: 'Error in seo module' })
    }

    if (pageWithNestedData?.entityName) {
      setContextDataByKey('entity_name', prepareSeoTemplateByVariable(pageWithNestedData.entityName, flatContext.value).text)
    }

    let canonicalUrl = ''
    if (canonicalPath) {
      canonicalUrl = `${nuxtApp.$config.public.BASE_URL}${canonicalPath}`
    } else {
      canonicalUrl = canonicalDefault.value
    }

    setContextDataByKey('entity_title', prepareSeoTemplateByVariable(pageWithNestedData.title, flatContext.value).text)
    setContextDataByKey('entity_heading', prepareSeoTemplateByVariable(pageWithNestedData.heading, flatContext.value).text)
    setContextDataByKey('entity_meta_description', prepareSeoTemplateByVariable(pageWithNestedData.metaDescription, flatContext.value).text)
    setContextDataByKey('entity_address', prepareSeoTemplateByVariable(canonicalUrl, flatContext.value).text)

    if (Number(router.currentRoute.value.query?.p) > 1) {
      setContextDataByKey('page', ` - Page #${router.currentRoute.value.query.p}`)
    } else {
      setContextDataByKey('page', '')
    }

    type PrepareKeyInData = { [key: string]: (data: string | undefined) => string }
    const prepareKeyInData: PrepareKeyInData = {
      entity_name: (data) => {
        return seoParams?.entityName || String(data)
      },
      entity_breadcrumb: (data) => {
        return seoParams?.breadcrumb || pageWithNestedData?.breadcrumb || String(data)
      }
    }

    for (const _key in data) {
      const key = _key as keyof ISeoTemplateVariablesFlatten
      let dataValue = data[key] || ''
      if (typeof prepareKeyInData[_key] === 'function') {
        dataValue = prepareKeyInData[_key](dataValue)
      }
      setContextDataByKey(key, prepareSeoTemplateByVariable(dataValue, flatContext.value).text)
    }

    if (!Array.isArray(pageWithNestedData.contentJsonLd)) {
      pageWithNestedData.contentJsonLd = []
    }
    if (!Array.isArray(pageWithNestedData.blocksJsonLd)) {
      pageWithNestedData.blocksJsonLd = []
    }

    return {
      canonicalUrl,
      data,
      identifier,
      page: pageWithNestedData,
      singleSeoParams: cloneDeep(seoParams) as Partial<IApiContentSeoPagesItem>,
    }
  }

  const applySeoForPageStatic = () => {
    state.value.useHead.link?.push({
      key: 'apple-touch-icon',
      rel: 'apple-touch-icon',
      sizes: "180x180",
      href: '/favicon/apple-touch-icon.png?v=1',
    })

    state.value.useHead.link?.push({
      key: 'icon',
      rel: 'icon',
      type: 'image/png',
      href: '/favicon/favicon-32x32.png?v=1',
    })

    state.value.useHead.link?.push({
      key: 'site-webmanifest',
      rel: 'manifest',
      href: '/favicon/site.webmanifest?v=1',
    })

    state.value.useHead.link?.push({
      key: 'mask-icon',
      rel: 'mask-icon',
      href: '/favicon/safari-pinned-tab.svg?v=1',
      color: '#DD066D',
    })

    state.value.useHead.link?.push({
      key: 'shortcut-icon',
      rel: 'shortcut icon',
      href: '/favicon.ico?v=1',
      color: '#DD066D',
    })

    state.value.useHead.meta?.push({
      key: 'apple-mobile-web-app-title',
      name: 'apple-mobile-web-app-title',
      content: flatContext.value.site_name || 'VR Smash',
    })

    state.value.useHead.meta?.push({
      key: 'application-name',
      name: 'application-name',
      content: flatContext.value.site_name || 'VR Smash',
    })

    state.value.useHead.meta?.push({
      key: 'msapplication-TileColor',
      name: 'msapplication-TileColor',
      content: '#121212',
    })

    state.value.useHead.meta?.push({
      key: 'msapplication-config',
      name: 'msapplication-config',
      content: '/favicon/browserconfig.xml?v=1',
    })

    state.value.useHead.meta?.push({
      key: 'theme-color',
      name: 'theme-color',
      content: '#121212',
    })
  }

  const applySeoForPage = (config:  ReturnType<typeof defineSeoPage>, { isParticle = false } = {}) => {
    state.value.useHead.meta = []
    state.value.useHead.link = []
    state.value.useHead.script = []
    state.value.useHead.noscript = []

    state.value.useHead.meta.push({
      key: 'referrer',
      name: 'referrer',
      content: 'strict-origin-when-cross-origin',
    })

    if (nuxtApp.$config.public.APP_IS_PROD) {
      state.value.useHead.script.push({
        key: 'google-tag',
        type: 'text/javascript',
        tagPosition: 'head',
        innerHTML: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PG3JBM');
        `,
      })

      state.value.useHead.noscript.push({
        key: 'google-tag:noscript',
        tagPosition: 'bodyOpen',
        innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PG3JBM" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
      })
    }

    applySeoForPageStatic()

    if (!config?.identifier) {
      return null
    }

    if (config.page.heading && isParticle) {
      heading.value = prepareSeoTemplateByVariable(config.page.heading, flatContext.value).text
    } else if (config.page.heading) {
      if (import.meta.server) {
        heading.value = prepareSeoTemplateByVariable(config.page.heading, flatContext.value).text
      } else {
        nuxtApp.hooks.hookOnce('page:finish', () => {
          return new Promise((resolve) => {
            heading.value = prepareSeoTemplateByVariable(config.page.heading, flatContext.value).text
            resolve()
          })
        })
      }
    }

    const canonical = prepareSeoTemplateByVariable(config.canonicalUrl, flatContext.value).text

    state.value.useHead.link?.push({
      key: 'canonical',
      rel: 'canonical',
      href: canonical,
    })

    if (config.page.title) {
      state.value.useHead.title = prepareSeoTemplateByVariable(config.page.title, flatContext.value).text
    }

    if (config.page.metaDescription) {
      state.value.useHead.meta.push({
        key: 'description',
        name: 'description',
        content: prepareSeoTemplateByVariable(config.page.metaDescription, flatContext.value).text,
      })
    }

    if (config.page.metaKeywords) {
      state.value.useHead.meta.push({
        key: 'keywords',
        name: 'keywords',
        content: prepareSeoTemplateByVariable(config.page.metaKeywords, flatContext.value).text,
      })
    }

    if (config.page.metaRobots) {
      state.value.useHead.meta.push({
        key: 'robots',
        name: 'robots',
        content: prepareSeoTemplateByVariable(config.page.metaRobots, flatContext.value).text,
      })
    }

    /**
     * @description
     * #### Правила генерации {ogParams.image} и {twitterParams.imageLink}
     * 1. Model Profile - картинка, которая выводится в качестве превью на карточку модели.
     * 2. Single Video - картинка, которая выводится в качестве превью на карточку видео.
     * 3. Studio Profile - картинка, которая выводится в качестве превью на карточку студии.
     * 4. Cam Girl Profile - картинка, которая выводится в качестве превью на карточку камгел.
     * 5. Article Image - картинка, которая выводится в качестве превью на карточку статьи.
     * */
    const checkEntityImage = () => {
      const haveEntities = [
        SEO_ENTITY_PAGE_TYPE.pornstarProfile,
        SEO_ENTITY_PAGE_TYPE.pornstarsMalePage,
        SEO_ENTITY_PAGE_TYPE.pornstarsTransPage,
        SEO_ENTITY_PAGE_TYPE.pornstarsCgiPage,
        SEO_ENTITY_PAGE_TYPE.singleVideo,
        SEO_ENTITY_PAGE_TYPE.singleGame,
        SEO_ENTITY_PAGE_TYPE.camgirlsProfilePage,
        SEO_ENTITY_PAGE_TYPE.article,
      ]
      return haveEntities.includes(config.identifier)
    }

    // openGraph
    if (config.page.isOG && config.page.ogParams?.type) {
      state.value.useHead.meta.push({
        key: 'og:type',
        property: 'og:type',
        content: config.page.ogParams.type,
      })

      state.value.useHead.meta.push({
        key: 'og:site_name',
        property: 'og:site_name',
        content: prepareSeoTemplateByVariable(config.page.ogParams.siteName, flatContext.value).text,
      })

      if (config.page.ogParams.title || config.page.title) {
        state.value.useHead.meta.push({
          key: 'og:title',
          property: 'og:title',
          content: prepareSeoTemplateByVariable(config.page.ogParams.title || config.page.title, flatContext.value).text,
        })
      }

      if (config.page.ogParams.description || config.page.metaDescription) {
        state.value.useHead.meta.push({
          key: 'og:description',
          property: 'og:description',
          content: prepareSeoTemplateByVariable(config.page.ogParams.description || config.page.metaDescription, flatContext.value).text,
        })
      }

      state.value.useHead.meta.push({
        key: 'og:url',
        property: 'og:url',
        content: prepareSeoTemplateByVariable(config.page.ogParams.url || canonical, flatContext.value).text,
      })

      switch (config.page.ogParams.type) {
        case 'website':
        case 'video.other':
          break
          break
        case 'article':
          break
      }
      if (config.page.image?.path) {
        state.value.useHead.meta.push({
          key: 'og:image:url',
          property: 'og:image:url',
          content: config.page.image.path,
        })
        state.value.useHead.meta.push({
          key: 'og:image:secure_url',
          property: 'og:image:secure_url',
          content: config.page.image.path,
        })
        // type reserved from admin-side v-file-img component
        state.value.useHead.meta.push({
          key: 'og:image:type',
          property: 'og:image:type',
          content: 'image/jpeg',
        })
        state.value.useHead.meta.push({
          key: 'og:image:width',
          property: 'og:image:width',
          content: config.page.image.width,
        })
        state.value.useHead.meta.push({
          key: 'og:image:height',
          property: 'og:image:height',
          content: config.page.image.height,
        })
        if (config.page.image.alt) {
          state.value.useHead.meta.push({
            key: 'og:image:alt',
            property: 'og:image:alt',
            content: config.page.image.alt,
          })
        }
      }
    }

    // twitter params
    if (config.page.isTwitterParams) {
      if (config.page.twitterParams.title || config.page.title) {
        state.value.useHead.meta.push({
          key: 'twitter:title',
          property: 'twitter:title',
          content: prepareSeoTemplateByVariable(config.page.twitterParams.title || config.page.title, flatContext.value).text,
        })
      }
      if (config.page.twitterParams.card) {
        state.value.useHead.meta.push({
          key: 'twitter:card',
          property: 'twitter:card',
          content: prepareSeoTemplateByVariable(config.page.twitterParams.card, flatContext.value).text,
        })
      }
      if (config.page.twitterParams.creator) {
        state.value.useHead.meta.push({
          key: 'twitter:creator',
          property: 'twitter:creator',
          content: prepareSeoTemplateByVariable(config.page.twitterParams.creator, flatContext.value).text,
        })
      }
      if (config.page.twitterParams.description || config.page.metaDescription) {
        state.value.useHead.meta.push({
          key: 'twitter:description',
          property: 'twitter:description',
          content: prepareSeoTemplateByVariable(config.page.twitterParams.description || config.page.metaDescription, flatContext.value).text,
        })
      }
      if (config.page.twitterParams.site) {
        state.value.useHead.meta.push({
          key: 'twitter:site',
          property: 'twitter:site',
          content: prepareSeoTemplateByVariable(config.page.twitterParams.site, flatContext.value).text,
        })
      }
      if (config.page.twitterParams.domain) {
        state.value.useHead.meta.push({
          key: 'twitter:domain',
          property: 'twitter:domain',
          content: prepareSeoTemplateByVariable(config.page.twitterParams.domain, flatContext.value).text,
        })
      }
      if (config.page.image?.path) {
        state.value.useHead.meta.push({
          key: 'twitter:image',
          property: 'twitter:image',
          content: config.page.image.path,
        })
      }
    }

    if (import.meta.client && !isRenderClientFlag) {
      return null
    }

    // scheme.org
    const jsonSpaces = nuxtApp.$config.public.APP_IS_PROD ? 0 : 2
    const prepareJson = (template: string) => {
      try {
        const text = prepareSeoTemplateByVariable(template, flatContext.value).text
        const preparedTemplate = prepareSeoTemplateByVariable(text, flatContext.value).text
        const jsonObject = JSON.parse(preparedTemplate)
        if (jsonObject) {
          return JSON.stringify(jsonObject, null, jsonSpaces)
        }
        return ''
      } catch (e) {
        useConsole().error('useSeoModule', 'prepareJson', e)
        return ''
      }
    }

    if (
      config.page.isJsonScriptLd
      && config.singleSeoParams?.isJsonScriptLd
      && config.singleSeoParams?.contentJsonLd
      && config.singleSeoParams.contentJsonLd[0]
    ) {
      // entity-type-item
      const contentJsonLd = prepareJson(config.singleSeoParams.contentJsonLd[0])
      if (contentJsonLd) {
        state.value.useHead.script.push({
          key: 'entity-type-item:contentJsonLd',
          type: 'application/ld+json',
          innerHTML: contentJsonLd,
        })
      }
    } else if (config.page.isJsonScriptLd && config.page.contentJsonLd[0]) {
      // entity-type or entity-page
      const contentJsonLd = prepareJson(config.page.contentJsonLd[0])
      if (contentJsonLd) {
        state.value.useHead.script.push({
          key: 'entity-type:contentJsonLd',
          type: 'application/ld+json',
          innerHTML: contentJsonLd,
        })
      }
    }

    // other
    return null
  }

  const getBreadcrumbByPage = (identifier: SEO_ENTITY_PAGE_TYPE, text = '') => {
    const candidate = seoStore.pagesData.find((item) => identifier === item.name)
    if (!candidate && !text) {
      return text
    }
    if (text) {
      return prepareSeoTemplateByVariable(candidate?.breadcrumb, flatContext.value).text || text
    }
    return prepareSeoTemplateByVariable(candidate?.breadcrumb, flatContext.value).text || breadcrumbDefault.value
  }

  return {
    altTitlesTemplates: globalStore.altTitlesTemplates,
    getAltTitlesByKey: globalStore.altTitlesByKey,
    altTitlesSlug: EnumSeoAltTitlesSlug,
    state,
    heading,
    flatTemplates,
    flatContext,
    canonicalDefault,
    breadcrumbDefault,
    generateCompositeContextByItems,
    createOverrideContext,
    createImageTitle,
    createImageAlt,
    setTemplateDataByKey,
    setContextDataByKey,
    defineSeoPage,
    applySeoForPage,
    getBreadcrumbByPage,
  }
}
