<template>
  <article
    ref="cardElement"
    class="ui-video-card ui-card-effect"
    data-testid="elem_vrp_001454"
    @click="pushCanonicalUrl"
  >
    <header
      class="ui-video-card__header"
      :class="{
        'ui-card-effect__with-hover': !shortVideoPath,
      }"
      data-testid="elem_vrp_001455"
    >
      <nuxt-link
        :to="canonicalUrl"
        :title="videoLinkTitle"
        data-testid="elem_vrp_001456"
      >
        <img
          :src="image"
          :alt="videoLinkAlt"
          class="ui-video-card__cover"
          data-testid="elem_vrp_001457"
        />

        <transition name="fade" mode="out-in">
          <video
            v-if="isShortVideoVisible"
            ref="shortVideoElement"
            :src="shortVideoPath + `#t=${Math.round(shortVideoTime)}`"
            class="ui-video-card__cover"
            playsinline
            autoplay
            muted
            loop
            @error="forceHideShortVideo = true"
          />
        </transition>
      </nuxt-link>

      <ui-like
        :type="followType"
        :identifier="video.slug"
        :is-follow="video.isFollow"
        class="ui-video-card__like"
        @click.capture.stop
      />

      <ui-time class="ui-video-card__time" :video="video" />
    </header>

    <div class="ui-video-card__info" data-testid="elem_vrp_001458">
      <div class="ui-video-card__studio-image-wrapper" data-testid="elem_vrp_001460">
        <nuxt-link
          :to="studioCanonicalUrl"
          :title="studioLinkTitle"
          class="ui-video-card__studio-link"
          data-testid="elem_vrp_001461"
          @click.stop
        >
          <img
            :src="studioImage"
            :alt="studioLinkAlt"
            class="ui-video-card__studio-cover"
            data-testid="elem_vrp_001462"
          >
        </nuxt-link>
      </div>

      <div class="ui-video-card__secondary" data-testid="elem_vrp_001463">
        <div class="ui-video-card__info-mini" data-testid="elem_vrp_001464">
          <span class="ui-video-card__text ui-video-card__text--link" @click.stop="pushStudioCanonicalUrl" data-testid="elem_vrp_001465">
            {{ video.studio.name }}
          </span>
          <span class="ui-video-card__text" data-testid="elem_vrp_001466">{{ date }}</span>
        </div>

        <nuxt-link :to="canonicalUrl" class="ui-video-card__name" data-testid="elem_vrp_001467">
          {{ video.name }}
        </nuxt-link>

        <footer class="ui-video-card__footer" data-testid="elem_vrp_001467">
          <div class="ui-video-card__footer-item" data-testid="elem_vrp_001468">
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <use href="/svg/like.svg#like"/>
            </svg>
            <span class="ui-video-card__text" data-testid="elem_vrp_001470">{{ likes }}</span>
          </div>
          <div class="ui-video-card__footer-item" data-testid="elem_vrp_001469">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <use href="/svg/dialog.svg#dialog"/>
            </svg>
            <span class="ui-video-card__text" data-testid="elem_vrp_001470">{{ comments }}</span>
          </div>
        </footer>
      </div>
    </div>
  </article>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'
import { formatTimeAgo, useElementHover } from '@vueuse/core'
import type { IApiContentVideoItem } from '~/api/content/videos'

interface Props {
  video: IApiContentVideoItem
  playingVideoId: string
}

const props = defineProps<Props>()
const { video, playingVideoId } = toRefs(props)
const isTouched = computed(()=>video.value.id === playingVideoId.value)
const router = useRouter()
const canonicalUrl = computed((): RouteLocationRaw => {
  return { name: 'video-slug', params: { slug: video.value.slug } }
})

const pushCanonicalUrl = () => {
  router.push(canonicalUrl.value)
}

const studioCanonicalUrl = computed((): RouteLocationRaw => {
  return { name: 'studio-slug', params: { slug: video.value.studio.slug } }
})
const pushStudioCanonicalUrl = () => {
  router.push(studioCanonicalUrl.value)
}

const followType = computed(() => video.value?.isGame ? 'game' : 'video')
const image = usePlaceholdImage(video.value.previewImage?.path ? video.value.previewImage?.path : '', '378x212')
const studioImage = usePlaceholdImage(video.value.studio?.logo?.path ? video.value.studio?.logo?.path : '', '72x72')
const comments = useShortNumber(video.value.comments || 0)
const likes = useShortNumber(video.value.likes || 0)
const date = computed(() => {
  const date = video.value.publishedAt ? new Date(video.value.publishedAt * 1000) : new Date()
  return formatTimeAgo(date)
})

const seo = useSeoModule()
const overrideContext = seo.createOverrideContext({
  entity_name: video.value.name,
  entity_title: video.value.name,
  video_card_name: video.value.name,
  video_card_studio_name: video.value.studio.name,
  game_card_name: video.value.name,
  game_card_studio_name: video.value.studio.name,
  models_name: video.value.models.join(', '),
})

const altTitles = seo.getAltTitlesByKey(seo.altTitlesSlug.videoCard)

const typedAltTitles = computed(() => {
  if (video.value?.isGame) {
    return altTitles?.game || {}
  }
  if (video.value.isGay) {
    return altTitles?.gay || {}
  }
  if (video.value.isTrans) {
    return altTitles?.trans || {}
  }
  return altTitles?.base || {}
})



const videoLinkTitleText = computed(() => seo.createImageTitle(typedAltTitles.value?.previewImageLinkTitle, overrideContext) || video.value.name)
const videoLinkTitle = computed(() => {
  if (isHovered.value || isTouched.value) {
    return ''
  }
  return videoLinkTitleText.value
})

const videoLinkAltText = computed(() => seo.createImageAlt(typedAltTitles.value?.previewImageAlt, overrideContext) || video.value.name)
const videoLinkAlt = computed(() => {
  if (isHovered.value || isTouched.value) {
    return ''
  }
  return videoLinkAltText.value
})

const studioLinkTitle = computed(() => seo.createImageTitle(typedAltTitles.value?.studioImageLinkTitle, overrideContext) || video.value.studio?.name)
const studioLinkAlt = computed(() => seo.createImageAlt(typedAltTitles.value?.studioImageAlt, overrideContext) || video.value.studio?.name)

const shortVideoPath = computed(() => {
  if (video.value.shortVideo?.path && video.value.shortVideo?.path.endsWith('.mp4')) {
    return video.value.shortVideo.path
  }
  return null
})

const cardElement = ref<HTMLElement>()
const shortVideoElement = ref<HTMLVideoElement>()
const shortVideoTime = ref(0)
const forceHideShortVideo = ref(false)

const isHovered = useElementHover(cardElement, { delayEnter: 0, delayLeave: 200 })
const isShortVideoVisible = computed(() => !forceHideShortVideo.value && (isHovered.value || isTouched.value) && !!shortVideoPath.value)

watch(isShortVideoVisible, () => {
  if (!isShortVideoVisible.value && shortVideoElement.value) {
    shortVideoElement.value.pause()
    shortVideoTime.value = Number(shortVideoElement.value.currentTime || 0)
  }
})
</script>

<style lang="scss">
.ui-video-card {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  aspect-ratio: 336 / 297;
  max-width: 336rem;
  line-height: normal;

  @include bp-tabletXS('min') {
    aspect-ratio: 348 / 305;
    max-width: 348rem;
  }

  @include bp-desktopXS('min') {
    aspect-ratio: 378 / 322;
    max-width: 378rem;
  }

  &__header {
    position: relative;
    aspect-ratio: 336 / 189;
    max-width: 336rem;


    @include bp-tabletXS('min') {
      aspect-ratio: 348 / 195;
      max-width: 348rem;
    }

    @include bp-desktopXS('min') {
      aspect-ratio: 378 / 212;
      max-width: 378rem;
    }
  }

  &__cover {
    position: absolute;
    aspect-ratio: 336 / 189;
    max-width: 336rem;
    object-position: center;
    object-fit: cover;
    border-radius: 10rem;
    height: 100%;

    @include bp-tabletXS('min') {
      aspect-ratio: 348 / 195;
      max-width: 348rem;
    }

    @include bp-desktopXS('min') {
      aspect-ratio: 378 / 212;
      max-width: 378rem;
    }
  }

  .--with-bg {
    border-radius: 10rem;
    background: map-get($grayPalette, 'color_17');
  }

  &__info {
    margin: 12rem 0 0;
    position: relative;
    display: flex;
    gap: 12rem;

    @include bp-tabletXS('min') {
      margin: 14rem 0 0;
      gap: 14rem;
    }
  }

  &__studio-image-wrapper {
    width: 44rem;
    min-width: 44rem;
  }

  &__studio-link {
    display: block;
    height: 38rem;

    @include bp-tabletXS('min') {
      height: 44rem;
    }
  }

  &__studio-cover {
    width: 38rem;
    height: 38rem;
    aspect-ratio: 1;
    border-radius: 10rem;
    display: -webkit-box;
    overflow-y: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include bp-tabletXS('min') {
      width: 44rem;
      height: 44rem;
    }
  }

  &__secondary {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__info-mini {
    display: flex;
    gap: 14rem;
    line-height: calc(22rem + 4rem);

    @include bp-tabletXS('min') {
      line-height: 22rem;
    }
  }

  &__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    color: map-get($grayPalette, 'color_9b');
    font-size: 14rem;
    font-weight: 400;
    line-height: 22rem;
    letter-spacing: 0.28rem;

    &--link {
      &:hover {
        color: map-get($grayPalette, 'color_ff');
      }
    }
  }

  &__name {
    min-height: 22rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    color: map-get($grayPalette, 'color_ff');
    font-size: 14rem;
    font-style: normal;
    font-weight: 600;
    line-height: 22rem;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      color: map-get($grayPalette, 'color_ff');
    }

    @include bp-tabletXS('min') {
      min-height: 24rem;
      font-size: 16rem;
      line-height: 24rem;
    }
  }

  &__footer {
    margin: 4rem 0 0;
    display: flex;
    gap: 14rem;
  }

  &__footer-item {
    display: flex;
    align-items: center;
    gap: 4rem;

    svg {
      width: 16rem;
      height: 16rem;
      color: map-get($grayPalette, 'color_9b');
    }
  }

  &__like {
    @include bp-desktopXS('min') {
      opacity: 0;
    }
  }

  &:hover {
    .ui-video-card__like {
      opacity: 1;
    }
  }
}
</style>
