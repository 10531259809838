import EmojiPicker from 'vue3-emoji-picker'

export default defineNuxtPlugin({
  name: 'app:emoji-textarea',

  setup() {
    return {
      provide: { EmojiPicker },
    }
  },
})
