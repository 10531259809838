/**
 * @method POST
 * @description initAccountRemoval
 * */

export interface IApiAuthInitAccountRemovalProps {
  email: string
  password: string
}

export interface IApiAuthInitAccountRemovalResponse {

}

export default async function apiAuthInitAccountRemoval(payload: IApiAuthInitAccountRemovalProps) {
  const endpoint = '/user/initAccountRemoval'

  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.post(pathUser + endpoint, payload)
  })
}
