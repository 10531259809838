/**
 * @method POST
 * @description сброс пароля - устанавливает новый пароль к аккаунту (после перехода по ссылке из письма)
 * */

export interface IApiAuthResetPasswordProps {
  token: string
  type: string
  password: string
}

export default async function apiAuthResetPassword(payload: IApiAuthResetPasswordProps) {
  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.post(pathUser + '/wp/auth/resetPassword', payload)
  })
}
