/**
 * @method POST
 * @description меняем верификацию пользователю
 * */

export interface IApiAgeVerificationProps {
  uuid: string
  isVerified: boolean
}
export default async function apiContentVerification(payload: IApiAgeVerificationProps) {
  const endpoint = `/age-verification/users/${payload.uuid}/verification`

  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.post(pathUser + endpoint, payload)
  })
}
