/**
 * @method POST
 * @description removeAccount
 * */

export interface IApiAuthRemoveAccountProps {
  token: string
}

export default async function apiAuthRemoveAccount(payload: IApiAuthRemoveAccountProps) {
  const endpoint = '/user/removeAccount'

  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.post(pathUser + endpoint, payload)
  })
}
