<template>
  <slot />
</template>

<script lang="ts" setup>
import { useGlobalStore } from '~/stores'
import { useSeoStore } from '~/stores/seo'

const nuxtApp = useNuxtApp()
const globalStore = useGlobalStore()
const seoStore = useSeoStore()

const seoProviderData = await useAsyncData('seo:provider:data', async () => {
  const digest = await globalStore.getDigest(nuxtApp)
  const altTitles = await globalStore.getAltTitlesTemplates(nuxtApp)
  const pagesData = await seoStore.getPagesData(nuxtApp)

  return {
    digest,
    altTitles,
    pagesData,
  }
})

const seo = useSeoModule()

const virtualUseHead = computed(() => {
  return seo.state.value.useHead
})

useHead(virtualUseHead)
</script>
