import type { IApiContentSeoPagesItem } from '~/api/content/seo-page'

export enum SEO_ENTITY_PAGE_TYPE {
  page = 'page',
  home = 'home',
  article = 'article',
  singleVideo = 'single-video',
  singleGame = 'single-game',
  root404 = 'root-404',
  all = 'all-videos',
  new = 'new-all-videos',
  pornstarProfile = 'pornstar-profile',
  malePornstarProfile = 'male-pornstar-profile',
  transPornstarProfile = 'trans-pornstar-profile',
  cgiPornstarProfile = 'cgi-pornstar-profile',
  studioProfile = 'studio-profile',
  categoryPage = 'single-category-page',
  tagGay = 'tag-gay',
  tagTrans = 'tag-trans',
  tagGame = 'tag-game',
  tagCgi = 'tag-cgi',
  gayCategoryPage = 'single-gay-category-page',
  transCategoryPage = 'single-trans-category-page',
  gamesCategoryPage = 'single-games-category-page',
  gameCategoryWebxr = 'game-webxr',
  gameCategorySideloadvr = 'game-sideloadvr',
  gameCategoryAndroid = 'game-android',
  blogPage = 'blog-page',
  appPage = 'app-playa',
  howToPage = 'how-to-page',
  contactPage = 'contact-page',
  faqPage = 'faq-page',
  loginPage = 'login-page',
  signupPage = 'signup-page',
  premiumlpPlansPage = 'premium-lp-page',
  premiumlpFormPage = 'premium-lp-form',
  earnings = 'earnings',
  library = 'library',
  notification = 'notification',
  setting = 'setting',
  maintenance = 'maintenance',
  searchVideos = 'search-videos',
  searchPornstars = 'search-pornstars',
  searchVrCams = 'search-vr-cams',
  searchCategories = 'search-categories',
  searchStudios = 'search-studios',
  searchBlog = 'search-blog',
  searchGames = 'search-games',
  searchNotFound = 'search-not-found',
  join = 'join',
  buyTokensJoin = 'buy-tokens-join',
  buyTokensSelectToken = 'buy-tokens-select-token',
  categoriesPage = 'categories-page',
  categoriesListPage = 'categories-list-page',
  camgirlsPage = 'camgirls-page',
  camgirlsProfilePage = 'camgirls-profile-page',
  pornstarsPage = 'pornstars-page',
  pornstarsMalePage = 'pornstars-male',
  pornstarsTransPage = 'pornstars-trans',
  pornstarsCgiPage = 'pornstars-cgi',
  studiosListPage = 'studios-list-page',
  studiosGridPage = 'studios-grid-page',
  studiosExclusive = 'studios-exclusive',
  studiosNew = 'studios-new',
  studiosCgi = 'studios-cgi',
  studiosGame = 'studios-game',
  staticPage = 'static-page',
}

export const SEO_ENTITY_PAGE_MOCK: IApiContentSeoPagesItem = {
  id: 'unique-id',
  name: 'SEO Unavailable',
  title: 'SEO Unavailable',
  metaDescription: 'SEO Unavailable',
  metaKeywords: 'SEO Unavailable',
  metaRobots: 'index,follow',
  heading: 'SEO Unavailable',
  isIncludeInSiteMap: true,
  isJsonScriptLd: false,
  contentJsonLd: [],
  blocksJsonLd: [],
  isOG: true,
  image: {
    id: 'unique-id',
    width: 0,
    height: 0,
    alt: '',
    path: '/',
  },
  ogParams: {
    type: 'website',
    title: 'Open Graph Title',
    description: 'Open Graph Description',
    url: '/',
  },
  isTwitterParams: true,
  twitterParams: {
    card: '/',
    creator: '@exampleCreator',
    description: 'SEO Unavailable',
    domain: 'SEO Unavailable',
    site: 'SEO Unavailable',
  },
  createdAt: Date.now(),
  updatedAt: Date.now(),
}
