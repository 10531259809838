import { useScriptTag } from '@vueuse/core'

type CallbackArgs = {
  instance: Dreamcam['Sdk']
  BabylonTools: typeof window.Dreamcam.BabylonTools
  WebHRClient: typeof window.Dreamcam.WebHRClient
  sdk: typeof window.Dreamcam.SDK,
  webPlayer: typeof window.Dreamcam.WebPlayer
}

type Callback<T = void> = (arg: CallbackArgs) => Promise<T>

export async function useDreamcam<D>(callback: Callback<D>) {
  const isActivated = useState('isDreamcamActivated', () => false)
  const webPlayer = useState<typeof window.Dreamcam.WebPlayer | null>('dreamcam:webPlayer', () => null)
  const sdk = useState<typeof window.Dreamcam.SDK | null>('dreamcam:sdk', () => null)
  const instance = useState<Dreamcam['Sdk']>('dreamcam:instance', () => null)

  if (import.meta.server) {
    return { data: [] }
  }

  const nuxtApp = useNuxtApp()

  const activate = () => {
    useScriptTag('/js/bundle.mjs?v=18', async () => {
      try {
        webPlayer.value = window.Dreamcam.WebPlayer
        sdk.value = window.Dreamcam.SDK
        instance.value = window.Dreamcam.SDK.SdkFactory.createInstance({
          bss: {
            baseUrl: '/proxy-stream/api/clients/v1/',
            partnerId: nuxtApp.$config.public.REST_DREAMCAMTRUE_PARTNER_ID,
          },
          ns: {
            url: nuxtApp.$config.public.SOCKET_DREAMCAMTRUE_TARGET,
            pingIntervalInMillis: 5_000,
          },
          logger: {
            level: window.Dreamcam.SDK.Level.Debug,
          },
        })
        await callback({
          webPlayer: webPlayer.value,
          sdk: sdk.value,
          instance: instance.value,
          WebHRClient: window.Dreamcam.WebHRClient,
          BabylonTools: window.Dreamcam.BabylonTools,
        })
        isActivated.value = true
      } catch (e) {
        useConsole().warn('useDreamcam', 'activate', e)
      }
    }, { type: 'module', async: true })
  }

  if (!isActivated.value) {
    activate()
    return false
  }

  if (!isActivated.value || !webPlayer.value || !sdk.value || !instance.value) {
    throw 'dreamcam not activated'
  }

  return await callback({
    webPlayer: webPlayer.value,
    sdk: sdk.value,
    instance: instance.value,
    WebHRClient: window.Dreamcam.WebHRClient,
    BabylonTools: window.Dreamcam.BabylonTools,
  })
}

export async function useDreamcamInit() {
  const nuxtApp = useNuxtApp()

  await useDreamcam(async () => {
    setTimeout(() => {
      nuxtApp.$bus.emit('camgirls:sdk:initialized')
    }, 0)
  })
}
