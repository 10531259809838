import { useUserStore } from '~/stores/user'
import { AFF_ID_COOKIE, OFFER_ID_COOKIE, JS_HIT_COOKIE } from "~/shared/constants/jshit"

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) return

  const nuxt = useNuxtApp()
  const userStore = useUserStore()
  const userAgent = useDevice().userAgent
  const setCookie = async (query) => {
    const cookieJsHit = useCookie<boolean>(JS_HIT_COOKIE)
    if (cookieJsHit.value) return

    await useAsyncQuery(async ({ axios, pathUser }) => {
      return await axios.get(pathUser + `/hit?link_data=${query.link_data}`)
    })
  }

  const getJsHitPayload = (query) => {
    const payload = {}
    const extPayload = {}
    const extPayloadKeys = ['click_id', 'cid', 'pid', 'sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'hash', 'token', 'sid']
    const isUnzipLink = Object.prototype.hasOwnProperty.call(query, 'link_data')
    const ipKeyName = 'remote_address'
    const uuidKeyName = 'statistic_visitor_uuid'
    let rawData = query

    if (isUnzipLink) {
      rawData = JSON.parse(atob(decodeURIComponent(query.link_data)))
    }

    payload.aff_id = rawData[AFF_ID_COOKIE]
    payload.offer_id = rawData[OFFER_ID_COOKIE]
    payload.link_id = rawData.link_id
    payload.type = rawData.type
    payload.creative_id = rawData.creative_id
    payload.click_id = rawData.click_id
    payload.user_agent = userAgent

    payload.campaign_id = rawData.campaign_id ? rawData.campaign_id : isUnzipLink ? '' : null
    payload.statistic_visitor_uuid = rawData[uuidKeyName]
    // payload[ipKeyName] = nuxt.ssrContext?.event.node.req.headers['x-real-ip']

    if (isUnzipLink) extPayload.source_item = 'unzipvr'
    else {
      extPayload.affiliateid = payload.aff_id
      extPayload.event_timestamp = Math.round(Date.now() / 1000)
      for (const key of extPayloadKeys) {
        extPayload[key] = rawData[key] || null
      }
    }

    return { ...payload, ...extPayload }
  }

  // if (!$config.isEnableJsHitMiddleware) return

  const unzipEndpoint = '/jshit_'

  const isUnzipLink = to.query.link_data
  const isJsHitRequest = !!isUnzipLink

  // const sendJsHitData = async (endpoint, payload) => {
  //   console.info(` jshit payload (${endpoint}) :>> `, payload)
  //   try {
  //     const header = 'If-None-Match'
  //     const res = await useAsyncQuery(async ({ axios, pathUnzip }) => {
  //       return await axios.post(pathUnzip + endpoint, payload)
  //     })
  //     console.info(`jshit response headers :>> `, res.headers)
  //     console.info(` jshit request headers :>> `, res.config.headers)
  //     console.info(` jshit response data :>> `, res.data)
  //   } catch (e) {
  //     console.info(` error :>> `, e)
  //   }
  // }

  if (isJsHitRequest) {
    let jsHitPayload = getJsHitPayload(to.query)
    userStore.setJsHit(to.query.link_data)
    userStore.setAffiliateId(jsHitPayload.aff_id)
    setCookie(to.query)
    // sendJsHitData(unzipEndpoint, jsHitPayload)
  }
})
