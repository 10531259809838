import type { ISeoTemplateVariablesFlattenPartial, ISeoTemplateVariablesKeys } from '~/shared/types/composables/seo'
import type { IApiContentStudiosListItem } from '~/api/content/studios-list'

export function getSeoAggregatesStudio(
  context: ISeoTemplateVariablesFlattenPartial,
  variable: ISeoTemplateVariablesKeys,
  item: IApiContentStudiosListItem,
) {
  if ('_skeleton' in item) {
    return '_skeleton'
  }

  switch (variable) {
    case 'model_studio_name':
      return item.name
    case 'model_studio_address':
      return `${context.site_address}studio/${item.slug}/`
    default:
      if (context[variable]) {
        return context[variable]
      }
      return `{var ${variable}: not define}`
  }
}
