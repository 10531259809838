import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'

const generateCanonicalUrl = (route: RouteLocationNormalized): RouteLocationRaw => {
  // change with save sort-order
  const allowKeys = ['query', 'with', 'f', 'p']
  const filterWithoutSortValues = ['list', 'exclusive', 'cgi', 'game', '']

  const queries: { [key: string]: string } = {}
  Object.keys(route.query).forEach((item) => {
    if (!allowKeys.includes(item)) {
      return
    }
    if (item === 'f') {
      if (filterWithoutSortValues.includes(route.query[item] as string)) {
        queries[item] = route.query[item] as string
      }
      return
    }

    if (item === 'p') {
      const page = Number(route.query[item] || 0)
      if (page > 1 && 5000 > page) {
        queries[item] = route.query[item] as string
      }
      return
    }

    queries[item] = route.query[item] as string
  })

  const sortedQueries: typeof queries = {}
  allowKeys.forEach((key) => {
    if (!queries[key]) {
      return
    }
    sortedQueries[key] = queries[key]
  })

  return {
    name: route.name as string,
    params: route.params,
    query: sortedQueries,
  }
}

export default function useCanonicalUrl() {
  const router = useRouter()
  const currentRoute = useRoute()

  const route = generateCanonicalUrl(currentRoute)
  const canonicalRoute = router.resolve(route)

  let fullPath = canonicalRoute.fullPath
  if (!fullPath.includes('/?') && fullPath.includes('?')) {
    fullPath = fullPath.replace('?', '/?')
  } else if (!fullPath.includes('/?') && !fullPath.endsWith('/')) {
    fullPath += '/'
  }

  return fullPath
}
