<template>
  <div class="ui-time" data-testid="element_000210">
    <div v-if="video?.isWebXR || video?.isSideload" class="ui-time__wrapper" data-testid="elem_vrp_000045">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <use href="/svg/global-green.svg#global-green" />
      </svg>
      <span data-testid="elem_vrp_000050">{{ video.isWebXR ? 'WebXR' : 'SideloadVR' }}</span>
    </div>
    <div v-else-if="video?.isPcGame" class="ui-time__wrapper" data-testid="elem_vrp_000045">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <use href="/svg/laptop.svg#laptop" />
      </svg>
      <span data-testid="elem_vrp_000050">PC Game</span>
    </div>
    <div v-else-if="video?.isAndroidGame" class="ui-time__wrapper" data-testid="elem_vrp_000045">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <use href="/svg/smartphone.svg#smartphone" />
      </svg>
      <span data-testid="elem_vrp_000050">Android</span>
    </div>
    <span v-else-if="timeText" data-testid="elem_vrp_000050">{{ timeText }}</span>
  </div>
</template>

<script lang="ts" setup>
import type { IApiContentVideoItem } from "~/api/content/videos"

interface Props {
  video?: IApiContentVideoItem
}

const props = defineProps<Props>()

const { video } = toRefs(props)

const formatTime = (timeInSeconds: number) => {
  const hours = Math.floor(timeInSeconds / 3600)
  const minutes = Math.floor((timeInSeconds % 3600) / 60)
  const seconds = timeInSeconds % 60
  if (hours > 0) {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  } else {
    return `${minutes.toString()}:${seconds.toString().padStart(2, '0')}`
  }
}

const timeText = computed(() => {
  if (!video.value?.time) {
    return formatTime(30 * 60 + 55)
  }
  return formatTime(video.value.time)
})
</script>

<style lang="scss">
.ui-time {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50rem;
    position: absolute;
    z-index: 2;
    right: 10rem;
    bottom: 10rem;
    background: map-get($tonePalette, 'tone_0');
    color: map_get($grayPalette, "color_ff");
    padding: 4rem 8rem;
    font-size: 12rem;
    line-height: 16rem;
    font-weight: 600;

    &__wrapper {
        display: flex;
        align-items: center;

        svg {
            margin-right: 6rem;
        }
    }
}
</style>
