import type { ISeoTemplateVariablesFlattenPartial, ISeoTemplateVariablesKeys } from '~/shared/types/composables/seo'

export default (
  template: string,
  flatContext: ISeoTemplateVariablesFlattenPartial,
  overrideContext: ISeoTemplateVariablesFlattenPartial = {},
) => {
  let isFall = false
  const falls: ISeoTemplateVariablesKeys[] = []
  if (!template || typeof template !== 'string') {
    return {
      falls,
      isFall: true,
      text: '',
    }
  }

  const text = template.replace(/%(.*?)%/g, (match: any, pass: string) => {
    const variable = pass.trim() as string as ISeoTemplateVariablesKeys
    if (!variable) {
      isFall = true
      falls.push(variable)
      return
    }

    let value = overrideContext[variable] || flatContext[variable]
    if (value && value.includes('"')) {
      value = value.replaceAll('"', "&quot;")
    }

    if (typeof value === 'undefined') {
      isFall = true
      falls.push(variable)
      if (match && match.startsWith('%') && match.endsWith('%')) {
        return `${match}`
      }
      return match
    }
    if (variable.endsWith('_block')) {
      try {
        value = JSON.stringify(value)
      } catch (e) {
        useConsole().error('prepareSeoTemplateByVariable', 'JSON invalid', e)
      }
    }
    return value
  })

  return {
    isFall,
    falls,
    text,
  }
}
