import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import type { RuntimeConfig } from 'nuxt/schema'
import { consola } from 'consola'
import { useUserStore } from '~/stores/user'

interface IArgs {
  axios: AxiosInstance
  path: string
  pathUser: string
  pathForum: string
  pathDreamcamtrue: string
  pathClient: string
  pathStatistics: string
  pathUnzip: string
  pathPopup: string
  pathSend: string
  config: RuntimeConfig['public']
}

type TQueryBaseResult = {
  $http: {
    status: number
    statusText: string
  }
  status?: {
    code?: number
  }
}
type TQueryCallback<D> = (args: IArgs) => Promise<AxiosResponse<D>>

export default async function <Data = unknown>(queryCallback: TQueryCallback<Data & TQueryBaseResult>) {
  const userStore = useUserStore()
  const nuxtApp = useNuxtApp()
  const config = nuxtApp.$config

  const {
    isAxiosInit,
    axiosInstance,
    noRetry$,
    basicHeaders,
  } = nuxtApp.$axiosPlugin

  const createAuthHeaders = () => {
    const { atoken } = userStore.getTokens()
    if (atoken) {
      return {
        [config.public.REST_BASE_TOKEN]: `Bearer ${atoken}`,
      } as { Authorization: string }
    }
  }

  const refreshAccessToken = async () => {
    await userStore.refresh()
    const { atoken } = userStore.getTokens()
    return atoken
  }

  if (!isAxiosInit.value) {
    axiosInstance.interceptors.request.use(async (config) => {
      await nuxtApp.runWithContext(() => {
        config.headers = Object.assign({}, basicHeaders, createAuthHeaders(), config.headers)
      })
      return config
    })

    axiosInstance.interceptors.response.use((response) => {
      return response
    }, async (error) => {
      if (import.meta.server) {
        await nuxtApp.runWithContext(() => {
          if (error.config) {
            useConsole().error('axiosInstance', 'response error', {
              method: error.config.method.toUpperCase(),
              url: error.config.url,
              body: error.config.data ? JSON.parse(error.config.data) : null,
              response: {
                status: error.response.status,
                content: error.response.headers['content-type'] || null,
              },
            })
          }
        })

        return Promise.reject(Object.assign(error, { useConsole: true }))
      }
      const originalRequest = error.config

      if (
        (error.response.status === 403 || error.response.status === 401) &&
        !originalRequest._retry &&
        !noRetry$.value
      ) {
        noRetry$.value = true
        originalRequest._retry = true
        let accessToken = ''
        try {
          accessToken = await refreshAccessToken()
          if (!accessToken) {
            return Promise.reject(Object.assign(error, { useConsole: true }))
          }
          noRetry$.value = false
          axiosInstance.defaults.headers.common[config.public.REST_BASE_TOKEN] =  `Bearer ${accessToken}`
          return Promise.resolve(axiosInstance(originalRequest))
        } catch (error2) {
          await userStore.logout()
          noRetry$.value = false
          return Promise.reject(Object.assign(error.response, { useConsole: true }))
        }
      }
      return Promise.resolve(Object.assign(error.response))
    })

    isAxiosInit.value = true
  }

  const path = config.public.API_ENDPOINT
  const pathUser = config.public.API_USER_ENDPOINT
  const pathForum = config.public.API_FORUM_ENDPOINT
  const pathDreamcamtrue = config.public.API_DREAMCAMTRUE_ENDPOINT
  const pathClient = config.public.BASE_URL
  const pathStatistics = config.public.API_STATISTICS_PATH
  const pathUnzip = config.public.API_UNZIP_PATH
  const pathPopup = config.public.API_POPUP_PATH
  const pathSend = config.public.API_SEND_PATH

  try {
    const response = await queryCallback({
      axios: axiosInstance,
      config: nuxtApp.$config.public,
      path,
      pathUser,
      pathForum,
      pathDreamcamtrue,
      pathClient,
      pathStatistics,
      pathUnzip,
      pathPopup,
      pathSend,
    })
    const $http = {
      status: response?.status,
      statusText: response?.statusText,
    }

    return {
      ...(typeof response?.data === 'object' ? {
        ...response?.data,
      } : { data: response?.data }),
      $http,
    }
  } catch (e: any) {
    const message = typeof e.message === 'string' ? e.message : 'Unknown error'

    if (!e.useConsole) {
      nuxtApp.runWithContext(() => {
        useConsole().error('useAsyncQuery', 'Failed query:', message)
      })
    }
  }
}
