import { consola } from 'consola'

export default function useConsole() {
  const config = useRuntimeConfig()
  const canConsole = () => {
    if (import.meta.server) {
      return true
    }
    return config.public.APP_DEBUG && !config.public.APP_IS_PROD
  }

  type TCallConsoleType = 'log' | 'info' | 'warn' | 'error'
  const callConsole = (type: TCallConsoleType, structure: string, message?: string, ...args: any[]) => {
    if (!canConsole()) {
      return
    }
    consola[type](`[${structure}]: ${message}`, ...args)
  }

  return {
    log (structure: string, message: string, ...args: any[]) {
      callConsole('log', structure, message, ...args)
    },
    info (structure: string, message: string, ...args: any[]) {
      callConsole('info', structure, message, ...args)
    },
    warn (structure: string, message: string, ...args: any[]) {
      callConsole('warn', structure, message, ...args)
    },
    error (structure: string, message: string, ...args: any[]) {
      callConsole('error', structure, message, ...args)
    },
  }
}
