/**
 * @method GET
 * @description Запросить dreamcamtrue баланс токенов
 */

export interface IApiDreamcamtrueBalanceProps {}

export default async function apiDreamcamtrueBalance(payload: IApiDreamcamtrueBalanceProps = {}) {
  return await useAsyncQuery<{ data: { balance: number } }>(async ({ axios, pathUser }) => {
    const endpoint = '/dreamcam/v1/balance'
    return await axios.get(pathUser + endpoint)
  })
}
