import { defineNuxtPlugin } from '#app'
import { useGlobalStore } from '~/stores'

export default defineNuxtPlugin({
  name: 'app:initialization',
  dependsOn: ['app:axios-instance'],

  async setup(nuxtApp) {
    // deps
    const globalStore = useGlobalStore()

    async function initializationGlobal() {
      await globalStore.initializationGlobal()
    }
    async function initializationServerOnly() {
      await globalStore.initializationServerOnly()
    }
    async function initializationClientOnly() {
      await globalStore.initializationClientOnly()
    }
    async function initializationFinish() {
      await globalStore.initializationFinish()
    }

    // app:global
    await initializationGlobal()
    // app:server
    await useServerOnly(initializationServerOnly)
    // app:client
    await useClientOnly(initializationClientOnly)
    // app:finish
    await initializationFinish()
  },
})
