import type { TApiFallbackKeys } from '~/shared/constants/api'
import { API_FALLBACK_KEYS } from '~/shared/constants/api'

export default async function<T> (id: TApiFallbackKeys, queryFn: () => Promise<T>) {
  const filePath = API_FALLBACK_KEYS[id]
  let response = {
    $http: {},
    data: {},
  }

  try {
    response = await queryFn()
    if (response?.$http?.status === 200) {
      return { response }
    }
  } catch (e) {
    useConsole().info('withCacheQuery', 'original query error, trying cache')
  }

  try {
    if (import.meta.server) {
      let payload = '{}'

      switch (id) {
        case 'digest':
          try {
            payload = (await import('~/public/api-cache/digest.json?raw')).default
          } catch (e) {
            useConsole().warn('withCacheQuery', 'Error load "digest.json" as raw', e)
          }
          break
        case 'seoPages':
          try {
            payload = (await import('~/public/api-cache/seo-pages.json?raw')).default
          } catch (e) {
            useConsole().warn('withCacheQuery', 'Error load "seo-pages.json" as raw', e)
          }
          break
      }

      response.data = JSON.parse(payload)
    } else {
      response = await useAsyncQuery(async ({ axios, pathClient }) => {
        return await axios.get(pathClient + filePath)
      })
    }
  } catch (e) {
    useConsole().error('withCacheQuery', 'build query fails', e)
  }

  return {
    response,
  }
}
