import { default as indexP2WkHHOuoTMeta } from "/var/www/vrp_content_front/pages/[...part]/index.vue?macro=true";
import { default as earningsquJsjFIiELMeta } from "/var/www/vrp_content_front/pages/account/earnings.vue?macro=true";
import { default as librarypMATelHHLvMeta } from "/var/www/vrp_content_front/pages/account/library.vue?macro=true";
import { default as notificationgfSz99vA4KMeta } from "/var/www/vrp_content_front/pages/account/notification.vue?macro=true";
import { default as settingvk1hqAtENgMeta } from "/var/www/vrp_content_front/pages/account/setting.vue?macro=true";
import { default as index2pCMWoZTnyMeta } from "/var/www/vrp_content_front/pages/age-verification/index.vue?macro=true";
import { default as indexPFb8p5fsAeMeta } from "/var/www/vrp_content_front/pages/all/index.vue?macro=true";
import { default as indexcEwqAkyHCxMeta } from "/var/www/vrp_content_front/pages/app/index.vue?macro=true";
import { default as _91slug_937Bc4l0c5SDMeta } from "/var/www/vrp_content_front/pages/blog/[slug].vue?macro=true";
import { default as indexrl9G82Dp84Meta } from "/var/www/vrp_content_front/pages/blog/index.vue?macro=true";
import { default as indexO3pckriBh8Meta } from "/var/www/vrp_content_front/pages/buy-tokens/index.vue?macro=true";
import { default as indexbEwxMkpz8qMeta } from "/var/www/vrp_content_front/pages/callback/index.vue?macro=true";
import { default as _91slug_93B8gQ5q9EZsMeta } from "/var/www/vrp_content_front/pages/camgirls/[slug].vue?macro=true";
import { default as indexzGybZMHhP9Meta } from "/var/www/vrp_content_front/pages/camgirls/index.vue?macro=true";
import { default as indexUZeMWcxHFNMeta } from "/var/www/vrp_content_front/pages/categories/index.vue?macro=true";
import { default as indexGuN7TcqWx6Meta } from "/var/www/vrp_content_front/pages/confirm-email/index.vue?macro=true";
import { default as indexLxdZSit9s0Meta } from "/var/www/vrp_content_front/pages/contact/index.vue?macro=true";
import { default as index9lHT6pZ0yaMeta } from "/var/www/vrp_content_front/pages/content-creators/index.vue?macro=true";
import { default as indexbhuKk0HsNCMeta } from "/var/www/vrp_content_front/pages/faq/index.vue?macro=true";
import { default as index8nbpom3g1dMeta } from "/var/www/vrp_content_front/pages/games/index.vue?macro=true";
import { default as index21Ga8HeSBzMeta } from "/var/www/vrp_content_front/pages/gay/index.vue?macro=true";
import { default as indexIFneQQlR7pMeta } from "/var/www/vrp_content_front/pages/how-to-watch-vr-porn-videos/index.vue?macro=true";
import { default as indexzLHliJ3kzdMeta } from "/var/www/vrp_content_front/pages/index.vue?macro=true";
import { default as indexnFMhuOOB3kMeta } from "/var/www/vrp_content_front/pages/login/index.vue?macro=true";
import { default as index4RNC4TObnEMeta } from "/var/www/vrp_content_front/pages/maintenance/index.vue?macro=true";
import { default as indexMijnkmal5BMeta } from "/var/www/vrp_content_front/pages/new/index.vue?macro=true";
import { default as indexgly46MqsH1Meta } from "/var/www/vrp_content_front/pages/pornstars-cgi/index.vue?macro=true";
import { default as indexztaTsOJwrFMeta } from "/var/www/vrp_content_front/pages/pornstars-male/index.vue?macro=true";
import { default as index4zDdGQX8ZOMeta } from "/var/www/vrp_content_front/pages/pornstars-trans/index.vue?macro=true";
import { default as index6aYiKUB7N2Meta } from "/var/www/vrp_content_front/pages/pornstars/index.vue?macro=true";
import { default as index9y86uZD1UaMeta } from "/var/www/vrp_content_front/pages/premiumlp/index.vue?macro=true";
import { default as indexYNCvhZvfqLMeta } from "/var/www/vrp_content_front/pages/reset-password/index.vue?macro=true";
import { default as _91tab_93mfaf684ditMeta } from "/var/www/vrp_content_front/pages/search/[tab].vue?macro=true";
import { default as indexyOgDvmjzTgMeta } from "/var/www/vrp_content_front/pages/signup/index.vue?macro=true";
import { default as _91slug_93cRLtFkTuSqMeta } from "/var/www/vrp_content_front/pages/studio/[slug].vue?macro=true";
import { default as indexvXvVzWfkDBMeta } from "/var/www/vrp_content_front/pages/studios/index.vue?macro=true";
import { default as _91slug_938Z8dg5PMqKMeta } from "/var/www/vrp_content_front/pages/tag/[slug].vue?macro=true";
import { default as indexokSwMikcvsMeta } from "/var/www/vrp_content_front/pages/trans/index.vue?macro=true";
import { default as _91slug_93SML1qm7vAnMeta } from "/var/www/vrp_content_front/pages/video/[slug].vue?macro=true";
export default [
  {
    name: "part",
    path: "/:part(.*)*",
    meta: indexP2WkHHOuoTMeta || {},
    component: () => import("/var/www/vrp_content_front/pages/[...part]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-earnings",
    path: "/account/earnings",
    component: () => import("/var/www/vrp_content_front/pages/account/earnings.vue").then(m => m.default || m)
  },
  {
    name: "account-library",
    path: "/account/library",
    component: () => import("/var/www/vrp_content_front/pages/account/library.vue").then(m => m.default || m)
  },
  {
    name: "account-notification",
    path: "/account/notification",
    component: () => import("/var/www/vrp_content_front/pages/account/notification.vue").then(m => m.default || m)
  },
  {
    name: "account-setting",
    path: "/account/setting",
    component: () => import("/var/www/vrp_content_front/pages/account/setting.vue").then(m => m.default || m)
  },
  {
    name: "age-verification",
    path: "/age-verification",
    meta: index2pCMWoZTnyMeta || {},
    component: () => import("/var/www/vrp_content_front/pages/age-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "all",
    path: "/all",
    component: () => import("/var/www/vrp_content_front/pages/all/index.vue").then(m => m.default || m)
  },
  {
    name: "app",
    path: "/app",
    meta: indexcEwqAkyHCxMeta || {},
    component: () => import("/var/www/vrp_content_front/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_937Bc4l0c5SDMeta || {},
    component: () => import("/var/www/vrp_content_front/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/var/www/vrp_content_front/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "buy-tokens",
    path: "/buy-tokens",
    meta: indexO3pckriBh8Meta || {},
    component: () => import("/var/www/vrp_content_front/pages/buy-tokens/index.vue").then(m => m.default || m)
  },
  {
    name: "callback",
    path: "/callback",
    meta: indexbEwxMkpz8qMeta || {},
    component: () => import("/var/www/vrp_content_front/pages/callback/index.vue").then(m => m.default || m)
  },
  {
    name: "camgirls-slug",
    path: "/camgirls/:slug()",
    meta: _91slug_93B8gQ5q9EZsMeta || {},
    component: () => import("/var/www/vrp_content_front/pages/camgirls/[slug].vue").then(m => m.default || m)
  },
  {
    name: "camgirls",
    path: "/camgirls",
    component: () => import("/var/www/vrp_content_front/pages/camgirls/index.vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/var/www/vrp_content_front/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "confirm-email",
    path: "/confirm-email",
    component: () => import("/var/www/vrp_content_front/pages/confirm-email/index.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexLxdZSit9s0Meta || {},
    component: () => import("/var/www/vrp_content_front/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "content-creators",
    path: "/content-creators",
    component: () => import("/var/www/vrp_content_front/pages/content-creators/index.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/var/www/vrp_content_front/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "games",
    path: "/games",
    component: () => import("/var/www/vrp_content_front/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: "gay",
    path: "/gay",
    component: () => import("/var/www/vrp_content_front/pages/gay/index.vue").then(m => m.default || m)
  },
  {
    name: "how-to-watch-vr-porn-videos",
    path: "/how-to-watch-vr-porn-videos",
    component: () => import("/var/www/vrp_content_front/pages/how-to-watch-vr-porn-videos/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vrp_content_front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/var/www/vrp_content_front/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: index4RNC4TObnEMeta || {},
    component: () => import("/var/www/vrp_content_front/pages/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: "new",
    path: "/new",
    component: () => import("/var/www/vrp_content_front/pages/new/index.vue").then(m => m.default || m)
  },
  {
    name: "pornstars-cgi",
    path: "/pornstars-cgi",
    component: () => import("/var/www/vrp_content_front/pages/pornstars-cgi/index.vue").then(m => m.default || m)
  },
  {
    name: "pornstars-male",
    path: "/pornstars-male",
    component: () => import("/var/www/vrp_content_front/pages/pornstars-male/index.vue").then(m => m.default || m)
  },
  {
    name: "pornstars-trans",
    path: "/pornstars-trans",
    component: () => import("/var/www/vrp_content_front/pages/pornstars-trans/index.vue").then(m => m.default || m)
  },
  {
    name: "pornstars",
    path: "/pornstars",
    component: () => import("/var/www/vrp_content_front/pages/pornstars/index.vue").then(m => m.default || m)
  },
  {
    name: "premiumlp",
    path: "/premiumlp",
    meta: index9y86uZD1UaMeta || {},
    component: () => import("/var/www/vrp_content_front/pages/premiumlp/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/var/www/vrp_content_front/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "search-tab",
    path: "/search/:tab()",
    component: () => import("/var/www/vrp_content_front/pages/search/[tab].vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/var/www/vrp_content_front/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "studio-slug",
    path: "/studio/:slug()",
    meta: _91slug_93cRLtFkTuSqMeta || {},
    component: () => import("/var/www/vrp_content_front/pages/studio/[slug].vue").then(m => m.default || m)
  },
  {
    name: "studios",
    path: "/studios",
    component: () => import("/var/www/vrp_content_front/pages/studios/index.vue").then(m => m.default || m)
  },
  {
    name: "tag-slug",
    path: "/tag/:slug()",
    component: () => import("/var/www/vrp_content_front/pages/tag/[slug].vue").then(m => m.default || m)
  },
  {
    name: "trans",
    path: "/trans",
    component: () => import("/var/www/vrp_content_front/pages/trans/index.vue").then(m => m.default || m)
  },
  {
    name: "video-slug",
    path: "/video/:slug()",
    meta: _91slug_93SML1qm7vAnMeta || {},
    component: () => import("/var/www/vrp_content_front/pages/video/[slug].vue").then(m => m.default || m)
  }
]