<template>
  <div class="app-root-block app-root-block--throw" data-testid="elem_vrp_000883">
    <seo-provider>
      <NuxtLayout name="default">
        <template v-if="canShowReallyError">
          <h1 class="mt2-12 mb2-0 stl-color-white" data-testid="elem_vrp_000885">Tehnical error:</h1>
          <pre class="stl-color-grey" style="text-wrap: pretty;" data-testid="elem_vrp_000886">
            {{ error }}
          </pre>
        </template>

        <pages-error-not-found v-else prevent-throw />
      </NuxtLayout>
    </seo-provider>
  </div>
</template>

<script lang="ts" setup>
const error = useError()
const runtimeConfig = useRuntimeConfig()
const canShowReallyError = computed(() => {
  return error.value?.statusCode && runtimeConfig.public.APP_DEBUG && error.value?.statusCode !== 404
})
</script>
