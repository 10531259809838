import type { IApiContentVideoItem } from '~/api/content/videos'
import type { ISeoTemplateVariablesFlattenPartial, ISeoTemplateVariablesKeys } from '~/shared/types/composables/seo'
import { getSeoAggregatesGame } from '~/shared/constants/aggregates/game'

export function getSeoAggregatesVideo(
  context: ISeoTemplateVariablesFlattenPartial,
  variable: ISeoTemplateVariablesKeys,
  item: IApiContentVideoItem,
) {
  if (!item) {
    return ''
  }
  if ('_skeleton' in item) {
    return null
  }
  if (item?.isGame) {
    return getSeoAggregatesGame(context, variable, item)
  }

  switch (variable) {
    case 'entity_name':
    case 'video_card_name':
      return item.name
    case 'video_card_address':
      return `${context.site_address}${item.slug}/`
    case 'video_card_trailer_embed_address':
      return `${context.site_address}${item.slug}/`
    case 'video_card_preview_image_address':
      return item.previewImage?.path
    case 'video_card_date_published':
      const timestamp = item?.createdAt || item?.publishedAt || (Date.now() / 1000)
      const date = new Date(timestamp * 1000)
      return date.toISOString()
    case 'video_card_studio_name':
      return item.studio.name
    case 'video_card_studio_address':
      return `${context.site_address}studio/${item.studio.slug}/`
    case 'video_card_studio_image':
      return item.studio?.logo?.path || ''
    case 'models_name':
      return item?.models?.join(', ') || ''
    default:
      if (context[variable]) {
        return context[variable]
      }
      return `{var ${variable}: not define}`
  }
}
