<template>
  <div class="app-root-block" data-testid="elem_vrp_000001">
    <seo-provider>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </seo-provider>

    <ui-global-store />
  </div>
</template>

<script lang="ts" setup>

</script>
