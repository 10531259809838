/**
 * @method GET
 * @description результат верификации
 * */

export interface IApiAgeVerificationProps {
    uuid: string
}
export default async function apiContentResultVerification(payload: IApiAgeVerificationProps) {
    const endpoint = `/age-verification/users/${payload.uuid}/has-verification?uuid=${payload.uuid}`

    return await useAsyncQuery(async ({ axios, pathUser }) => {
        return await axios.get(pathUser + endpoint)
    })
}
