export enum VRSMASH_STATIC_REDIRECTS {
  '/category/all' = '/categories/',
  '/category/10/Gay' = '/gay/',
  '/category/13/Trans' = '/trans/',
  '/category/7/For_Women' = '/tag/for-women/',
  '/category/999/Full' = '/tag/full/',
  '/category/premium' = '/new/',
  '/category/featuredpremium' = '/all/',
  '/category/latest' = '/new/',
  '/pornstars/all' = '/pornstars/',
  '/studio/all' = '/studios/',
  '/site/gettingStarted' = '/how-to-watch-vr-porn-videos/',
  '/insights' = '/blog/',
  '/billing/goPremium' = '/premiumlp/',
  '/info/affiliate' = '/affiliates/',
  '/info/contact' = '/contact/',
  '/info/terms' = '/terms-of-service/',
  '/info/privacy' = '/privacy-policy/',
  '/info/cookiePolicy' = '/cookie-policy/',
  '/info/dmca' = '/dmcacopyright/',
  '/info/ccpa' = '/ccpa/',
  '/info/complaints-policy' = '/complaint-policy/',
  '/info/18-U.S.C.-2257-Record-Keeping-Requirements-Compliance-Statement' = '/2257-statement/',
  '/video/search' = '/search/video/',
}

export const VRSMASH_DYNAMIC_REDIRECTS_REGEXP = /^\/(category|embed|view|pornstars|studio|compatible-vr-headsets)\/(\d+)\/.*$/

export enum VRSMASH_ENTITY_NAMES {
  category = 'category',
  view = 'video',
  embed = 'video',
  pornstars = 'model',
  studio = 'studio'
}

export enum VRSMASH_ROUTES {
  category = 'tag',
  view = 'video',
  embed = 'video',
  pornstars = 'pornstars',
  studio = 'studio'
}
