<template>
  <div
    v-if="isVisible"
    :class="{ 'ui-like--selected': followSystem.isFollowed.value }"
    class="ui-like"
    data-testid="element_000161"
    @click.capture.prevent.stop="followSystem.followAction"
  >
    <heart-icon viewBox="0 0 18 18" />
  </div>
</template>

<script lang="ts" setup>
import type { IFollowSystemOptions } from '~/shared/types/composables/composables'
import { useUserStore } from '~/stores/user'
import HeartIcon from '@/public/svg/heart.svg'

interface Props {
  type: IFollowSystemOptions['type']
  identifier: string | number
  isFollow: boolean
}

const props = defineProps<Props>()
const { type, identifier, isFollow } = toRefs(props)

const userStore = useUserStore()
const isVisible = computed(() => userStore.isAuthorized)
const followSystem = useFollowSystem({ type: type.value, key: identifier.value, isFollow: isFollow.value })
</script>

<style lang="scss">
.ui-like {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100rem;
  position: absolute;
  z-index: 2;
  right: 10rem;
  top: 10rem;
  width: 28rem;
  height: 28rem;
  background: map-get($tonePalette, 'tone_0');
  transition: 0.3s;
  color: map_get($grayPalette, "color_ff");

  svg {
    margin: 0;
    max-width: 100%;
    width: 18rem;
  }

  &.ui-like--selected svg {
    path:first-child {
      fill: map_get($grayPalette, "color_ff");
    }
  }

  @include bp-desktopXS('min') {
    &:hover {
      svg {
        path:first-child {
          fill: map_get($grayPalette, "color_ff") !important;
        }
      }
    }
  }
}
</style>
