/**
 * @method GET
 * @description получение нотификаций пользователя
 * */

import type { TNoticeItem } from '~/shared/types/notification/notification'

export interface IApiContentNotificationProps {
  page: number
  limit: number
}

export interface IApiContentNotificationResponse {
  data: {
    items: TNoticeItem[]
    pages: number
    unreadCount: number
  }
}

export default async function apiContentNotification(payload: IApiContentNotificationProps) {
  const endpoint = `/content/v1/notification?page=${payload.page}&limit=${payload.limit}`

  return await useAsyncQuery<IApiContentNotificationResponse>(async ({ axios, path }) => {
    return await axios.get(path + endpoint)
  })
}
