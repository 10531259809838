/**
 * @method PUT
 * @description change-name
 * */

export interface IApiAuthChangeNameProps {
  email: string
  name: string
}

export interface IApiAuthChangeNameResponse {}

export default async function apiAuthChangeName(payload: IApiAuthChangeNameProps) {
  const endpoint = '/user/change-name'

  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.put(pathUser + endpoint, payload)
  })
}
