import apiDreamcamtrueUserInfo, { type IApiDreamcamtrueUserInfoData } from "~/api/dreamcamtrue/user-info"
import apiDreamcamtrueBalance from "~/api/dreamcamtrue/balance"

function createEmptyUserInfo(): IApiDreamcamtrueUserInfoData {
  return {
    user_name: '',
    user_uuid: '',
    dreamcam_token: '',
  }
}

export const useDreamcamAuthStore = defineStore('dreamcamAuthStore', {
  state: () => {
    return {
      balance: 0,
      info: createEmptyUserInfo(),
    }
  },
  getters: {
    isAuthorized() {
      const userStore = useUserStore()
      return userStore.isAuthorized
    }
  },
  actions: {
    changeBalanceEvent() {
      this.getBalance()
    },

    getIsAuthorizedFromSdk(sdk: Dreamcam['Sdk']) {
      return sdk.authManager.isAuthorized
    },

    async getBalance() {
      if (!this.isAuthorized) {
        return
      }
      try {
        const response = await apiDreamcamtrueBalance()
        if (typeof response?.data?.balance === 'number') {
          this.balance = response.data.balance
        } else {
          this.balance = 0
        }
      } catch (e) {
        useConsole().warn('useDreamcamAuthStore', 'getBalance', e)
      }
    },

    async getInfo() {
      if (!this.isAuthorized) {
        return
      }

      try {
        const response = await apiDreamcamtrueUserInfo()
        if (response?.data?.dreamcam_token) {
          this.info = response.data
        } else {
          this.info = createEmptyUserInfo()
        }
      } catch (e) {
        useConsole().warn('useDreamcamAuthStore', 'getInfo', e)
      }
    },

    async login(): Promise<boolean> {
      if (import.meta.server) {
        return false
      }
      return await new Promise(async (resolve) => {
        if (!this.isAuthorized) {
          return false
        }

        await this.getInfo()

        await useDreamcam(async ({ instance }) => {
          try {
            const info = this.info
            if (!info.user_uuid) {
              return false
            }
            try {
              const authResponse = await instance.authManager.loginAsync(info.user_name, info.user_uuid, info.dreamcam_token)
              resolve(!!authResponse?.clientId)
            } catch (e) {
              resolve(false)
            } finally {
              if (this.isAuthorized) {
                instance.clientProfileManager.removeAllListeners('balanceChanged')
                instance.clientProfileManager.addListener('balanceChanged', this.changeBalanceEvent)
              }
            }
          } catch (e) {
            useConsole().warn('useDreamcamAuthStore', 'login', e)
            resolve(false)
          }
        })
      })
    },

    async logout() {
      await useDreamcam(async ({ instance }) => {
        if (!this.getIsAuthorizedFromSdk(instance)) {
          return false
        }
        try {
          await instance.authManager.logoutAsync()
          instance.clientProfileManager.removeAllListeners('balanceChanged')
        } catch (e) {
          useConsole().warn('useDreamcamAuthStore', 'logout', e)
          return false
        }
      })
    },
  },
})
