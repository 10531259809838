/**
 * @method GET
 * @description получение seo настроек для alt & titles
 * */

export interface IApiContentSeoAltTitlesProps {}

export interface IApiContentSeoAltTitlesResponseDataItem {
  base: {
    previewImageLinkTitle: string
    previewImageAlt: string
    studioImageLinkTitle: string
    studioImageAlt: string
  }
  trans: {
    previewImageLinkTitle: string
    previewImageAlt: string
    studioImageLinkTitle: string
    studioImageAlt: string
  }
  gay: {
    previewImageLinkTitle: string
    previewImageAlt: string
    studioImageLinkTitle: string
    studioImageAlt: string
  }
  game: {
    previewImageLinkTitle: string
    previewImageAlt: string
    studioImageLinkTitle: string
    studioImageAlt: string
  }
  recommended: {
    previewImageLinkTitle: string
    previewImageAlt: string
  }
  previewImageLinkTitle: string
  previewImageAlt: string
}

export enum EnumSeoAltTitlesSlug {
  blogCard = 'blog-card',
  camsCard = 'cams-card',
  categoryCard = 'category-card',
  modelCard = 'model-card',
  studioCard = 'studio-card',
  videoCard = 'video-card',
}

export type IApiContentSeoAltTitlesStore = {
  [key in (typeof EnumSeoAltTitlesSlug)[(keyof typeof EnumSeoAltTitlesSlug)]]: IApiContentSeoAltTitlesResponseDataItem
}

export interface IApiContentSeoAltTitlesResponseConstruct {
  id: string
  slug: EnumSeoAltTitlesSlug
  name: EnumSeoAltTitlesSlug
  params: IApiContentSeoAltTitlesResponseDataItem
  createdAt: number
  updatedAt: number
}

export interface IApiContentSeoAltTitlesResponse {
  status: {
    code: number
    message: string
  }
  data: {
    items: IApiContentSeoAltTitlesResponseConstruct[]
  }
}

export default async function apiContentSeoAltTitles(payload: IApiContentSeoAltTitlesProps = {}): Promise<IApiContentSeoAltTitlesResponse> {
  const endpoint = '/content/v1/seo/alt-titles'
  return await useAsyncQuery(async ({ axios, path }) => {
    return await axios.get(path + endpoint)
  })
}
