import { useUserStore } from '~/stores/user'
import type { IFollowSystemOptions } from '~/shared/types/composables/composables'

export default function useFollowSystem(options: IFollowSystemOptions) {
  const userStore = useUserStore()
  const nuxtApp = useNuxtApp()

  const isFollowed = ref(false)
  if (typeof options?.watched?.value === 'boolean') {
    isFollowed.value = options.watched.value
    watch(options.watched, (bool) => {
      isFollowed.value = bool
    })
  } else {
    isFollowed.value = options.isFollow || false
  }

  const isFollowLoading = ref(false)
  const isFollowDisabled = computed(() => {
    return isFollowLoading.value
  })

  const followAction = async () => {
    if (!userStore.isAuthorized) {
      const router = useRouter()
      await router.push({ name: 'login' })
      return
    }

    const endpoints: { [key: string]: string | (() => Promise<void>) } = {
      model: `/content/v1/models/${options.key}/follow`,
      studio: `/content/v1/studios/${options.key}/follow`,
      video: `/content/v1/videos/${options.key}/favorite`,
      game: `/content/v1/videos/${options.key}/favorite`,
      live: async () => {
        await useDreamcam(async ({ instance }) => {
          try {
            if (isFollowed.value) {
              await instance.clientProfileManager.favorites.removeModelAsync(options.key)
              isFollowed.value = false
              nuxtApp.$toast.info(`Camgirl has been deleted from favorites`)
            } else {
              await instance.clientProfileManager.favorites.addModelAsync(options.key)
              isFollowed.value = true
              nuxtApp.$toast.info(`Camgirl has been added to favorites`)
            }
          } catch (e) {
            useConsole().warn('useFollowSystem', 'followAction:live', e)
          }
        })
      },
    }

    isFollowLoading.value = true
    try {
      if (typeof endpoints[options.type] === 'function') {
        await endpoints[options.type]()
        return
      }

      const endpoint = endpoints[options.type]
      const result = await useAsyncQuery(async ({ axios, path }) => {
        return await axios.post(path + endpoint)
      })

      let typeLabelPrepare: string = options.type
      if (typeLabelPrepare === 'model') {
        typeLabelPrepare = 'pornstar'
      }
      let typeLabel: string | string[] = [...typeLabelPrepare]
      typeLabel[0] = typeLabel[0].toUpperCase()
      typeLabel = typeLabel.join('')

      if (result.$http.status === 201) {
        nuxtApp.$toast.info(`${typeLabel} has been added to favorites`)
        isFollowed.value = true
      }
      if (result.$http.status === 204) {
        nuxtApp.$toast.info(`${typeLabel} has been deleted from favorites`)
        isFollowed.value = false
      }
    } catch (e) {
      return
    } finally {
      nuxtApp.$io.notification.reconnect()
      isFollowLoading.value = false
    }
  }

  return {
    isFollowed,
    isFollowLoading,
    isFollowDisabled,
    followAction,
  }
}
