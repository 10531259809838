import type { ISeoTemplateVariablesFlattenPartial, ISeoTemplateVariablesKeys } from '~/shared/types/composables/seo'
import type { IApiContentCategoriesItem } from '~/api/content/categories'

export function getSeoAggregatesCategory(
  context: ISeoTemplateVariablesFlattenPartial,
  variable: ISeoTemplateVariablesKeys,
  item: IApiContentCategoriesItem,
) {
  if ('_skeleton' in item) {
    return '_skeleton'
  }

  switch (variable) {
    case 'category_preview_image':
      return item.categoryImage.path
    default:
      if (context[variable]) {
        return context[variable]
      }
      return `{var ${variable}: not define}`
  }
}
