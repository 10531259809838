import { defineStore, acceptHMRUpdate } from 'pinia'
import type { TNoticeItem } from '~/shared/types/notification/notification'
import apiContentNotification from '~/api/content/notification'
import {
  apiContentNotificationSettings, apiContentNotificationSettingsUpdate,
  type IApiContentNotificationSettingsPayload,
} from '~/api/content/notification-settings'

export const useNotificationsStore = defineStore('notificationsStore', {
  state() {
    return {
      unreadCount: 0,
      notifications: [] as TNoticeItem[],
    }
  },
  getters: {
    isUnreadBell(state) {
      return !!state.unreadCount
    },
  },
  actions: {
    async checkIfUnread() {
      try {
        const result = await apiContentNotification({ page: 1, limit: 16 })
        if (result?.data?.unreadCount) {
          this.setUnreadCount(result?.data.unreadCount)
        }
      } catch (e) {
        useConsole().info('[checkIfUnread]', 'empty')
      }
    },

    incrementUnreadCount() {
      this.setUnreadCount(this.unreadCount + 1)
    },

    decrementUnreadCount() {
      if (this.unreadCount) {
        this.setUnreadCount(this.unreadCount - 1)
      }
    },

    setUnreadCount(number: number) {
      this.unreadCount = number
    },

    async getSettings() {
      const result = await apiContentNotificationSettings()
      if (result?.$http?.status === 200) {
        return result?.data?.notificationSettings
      }
    },

    async updateSettings(payload: IApiContentNotificationSettingsPayload) {
      const result = await apiContentNotificationSettingsUpdate(payload)

      if (result?.$http?.status === 200 && result?.status?.code === 2) {
       return result
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationsStore, import.meta.hot))
}
