import { acceptHMRUpdate, defineStore } from 'pinia'
import { useUserStore } from '~/stores/user'
import apiContentDigest from '~/api/content/digest'
import apiContentSeoAltTitles, {
  EnumSeoAltTitlesSlug,
  type IApiContentSeoAltTitlesStore,
} from '~/api/content/seo-alt-titles-slug'
import type { IApiContentDigest } from '~/shared/types/digest/digest'
import type { NuxtApp } from '#app'

export const useGlobalStore = defineStore('globalStore', {
  state: () => {
    return {
      digest: {} as IApiContentDigest,
      digestVariables: {
        $currentYear: (new Date()).getFullYear(),
      },
      altTitlesTemplates: {} as IApiContentSeoAltTitlesStore,
    }
  },
  getters: {
    withVariable(state) {
      type TKeys = keyof typeof state.digestVariables
      const keys  = Object.keys(state.digestVariables)

      return (text: string = '') => {
        for (const key of keys) {
          text = text.replace(key, state.digestVariables[key])
        }

        return text
      }
    },
    siteSettings(state) {
      return state.digest.siteSettings
    },
    altTitlesByKey(state) {
      return (key: EnumSeoAltTitlesSlug) => {
        return state?.altTitlesTemplates[key] || {} as IApiContentSeoAltTitlesStore[EnumSeoAltTitlesSlug]
      }
    },
  },
  actions: {
    async initializationGlobal() {
      // get runtimeConfig from be-side, and basic info for header, footer, left menu
      const userStore = useUserStore()
      await userStore.restoreSession()
    },
    async initializationServerOnly() {
      // server code
    },
    async initializationClientOnly() {
      // client code
    },
    async initializationFinish() {
      // run seo plugin and postprocessing
    },

    getDigest(nuxtApp: NuxtApp) {
      return new Promise((resolve) => {
        nuxtApp.runWithContext(async () => {
          if (this.digest?.siteSettings) {
            resolve(this.digest)
            return
          }
          const { response } = await withCacheQuery('digest', apiContentDigest)
          if (response?.data?.digest) {
            this.digest = response.data.digest
          }
          resolve(this.digest)
        })
      })

    },

    getAltTitlesTemplates(nuxtApp: NuxtApp) {
      return new Promise((resolve) => {
        nuxtApp.runWithContext(async () => {
          const keys = Object.keys(this.altTitlesTemplates)
          if (keys.length) {
            resolve(this.altTitlesTemplates)
            return
          }
          const response = await apiContentSeoAltTitles()
          if (response?.data?.items) {
            for (const item of response.data.items) {
              this.altTitlesTemplates[item.slug] = item.params
            }
          }
          resolve(this.altTitlesTemplates)
        })
      })
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot))
}
