/**
 * @method POST
 * @description устанавливает новый пароль к аккаунту
 * */

export interface IApiAuthSetNewPasswordProps {
  password: string
  new_password: string
  new_password_confirmation: string
}

export default async function apiAuthSetNewPassword(payload: IApiAuthSetNewPasswordProps) {
  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.post(pathUser + '/user/change-password', payload)
  })
}
