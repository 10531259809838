/**
 * @method GET
 * @description получение информации о текущем пользователе
 * */

export interface IApiAuthUserProps {}

export type TAuthUserAuthorized = {
  data: {
    item: {
      email: string
      name: string
      uuid: string
      passwordChangedAt: {
        date: string
        timezone: string
        timezone_type: number
      }
    }
  }
  status: {
    code: 1
    message: 'ok'
  }
}

export type TAuthUserUnauthorized = {
  status: {
    code: 401
    message: 'Unauthorized.'
  }
}

export type IApiAuthUserResponse = TAuthUserAuthorized | TAuthUserUnauthorized

export default async function apiAuthUser(payload: IApiAuthUserProps = {}): Promise<IApiAuthUserResponse> {
  const endpoint = '/wp/user'

  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return axios.get(pathUser + endpoint)
  })
}
