/**
 * @method POST
 * @description request-change-email-from-url-action -> change-email-action -> confirmChangeEmailAction
 * @deprecated
 * */

export interface IApiAuthRequestChangeEmailActionProps {
  email: string // new email
  password: string
  token: string
}

export interface IApiAuthRequestChangeEmailActionResponse {

}

export default async function apiAuthRequestChangeEmailAction(payload: IApiAuthRequestChangeEmailActionProps) {
  const endpoint = '/user/request-change-email-action'

  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.post(pathUser + endpoint, payload)
  })
}
