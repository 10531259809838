// Doc: https://github.com/developit/mitt
import { consola } from 'consola'
import mitt from 'mitt'
import type { ICamgirlsMessage } from '~/shared/types/ui/vrcam'

export type ApplicationEvents = {
  /**
   * Translates to:
   * 'object:event:modification' -> event name
   * void -> type as payload
   * @example 'user:login': User (interface) or type [maybe void]
   */
  'app:layout:created': void
  'app:layout:mounted': void
  'camgirls:sdk:initialized': void
  'camgirls:single:refresh': void
  'camgirls:privateShowIsOver:send-compliment': void
  'camgirls:socket:broadcastViewersCount': number
  'camgirls:socket:clear-chat': void
  'camgirls:socket:connectToRoomChat': boolean
  'camgirls:socket:delete-all-user-messages': void
  'camgirls:socket:history-message': void
  'camgirls:socket:message': ICamgirlsMessage
  'camgirls:socket:welcome-can-scroll': void
  'comment:reply:increase': { postId: string, author: string, realReplyTo: number | string | null }
  'faq:detail:close-all': void
  'notifications:read': { id: string }
  'notifications:read-all': void
  'notifications:socket:new': void
  'video:time:update': { slug: string }
}

export default defineNuxtPlugin({
  name: 'app:event-bus',

  async setup() {
    const $config = useNuxtApp().$config

    const emitter = mitt<ApplicationEvents>()

    if ($config.public.APP_DEBUG) {
      emitter.on('*', (type, e: unknown) => {
        if (e) {
          consola.info(`[$bus]: ${type}`, e)
        } else {
          consola.info(`[$bus]: ${type}`)
        }
      })
    }

    return {
      provide: {
        bus: {
          emit: emitter.emit,
          on: emitter.on,
          off: emitter.off,
        },
      },
    }
  },
})
