/**
 * @method POST
 * @description восстановление пароля - отправляет ссылку на почту для сброса пароля
* */

export interface IApiAuthRestorePasswordProps {
  email: string
}

export default async function apiAuthRestorePassword(payload: IApiAuthRestorePasswordProps) {
  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.post(pathUser + '/wp/auth/restorePassword', payload)
  })
}
