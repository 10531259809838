import { defineNuxtPlugin } from "#app"

export default defineNuxtPlugin({
  name: 'app:nv-token',
  dependsOn: [],

  async setup(nuxtApp) {
    const nvToken = useState('nv-token', () => false)
    if (import.meta.client) {
      return {
        provide: {
          nvToken: nvToken.value
        }
      }
    }

    const headers = useRequestHeaders()
    if (headers['nv-token']) {
      nvToken.value = true
    }
    return {
      provide: {
        nvToken: nvToken.value
      }
    }
  },
})
