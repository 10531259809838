type TSises = '244x244' | '360x360' | '72x72' | '378x212' |
  '354x510' |'244x327' | '1584x565' | '126x327'

export function usePlaceholdImage(image: any, size: TSises) {
  if (image && typeof image === 'string' && image?.includes('//')) {
    return image
  }
  return `/img/placehold/${size}.svg`
}

