/**
 * @method POST
 * @description request-change-email-from-url-action -> change-email-action -> confirmChangeEmailAction
 * */

export default async function apiAuthRequestChangeEmailFromUrlAction() {
  const endpoint = '/user/request-change-email-from-url-action'

  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.post(pathUser + endpoint)
  })
}
