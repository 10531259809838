<template>
  <div class="app-skeleton-animated">
    <section v-if="strait" class="ui-video-card-skeleton-strait">
      <div class="ui-video-card-skeleton-strait__image app-skeleton-animated__panel" />
      <div class="ui-video-card-skeleton-strait__info">
        <div class="ui-video-card-skeleton-strait__company app-skeleton-animated__panel" />
        <div class="ui-video-card-skeleton-strait__title app-skeleton-animated__panel" />
        <div class="ui-video-card-skeleton-strait__icons app-skeleton-animated__panel" />
      </div>
    </section>
    <section v-else class="ui-video-card-skeleton">
      <div class="ui-video-card-skeleton__image app-skeleton-animated__panel" />
      <div class="ui-video-card-skeleton__info">
        <div class="ui-video-card-skeleton__avatar app-skeleton-animated__panel" />
        <div class="ui-video-card-skeleton__content">
          <div class="ui-video-card-skeleton__title-wrapper">
            <div class="ui-video-card-skeleton__title-1 app-skeleton-animated__panel" />
            <div class="ui-video-card-skeleton__title-2 app-skeleton-animated__panel" />
          </div>
          <div class="ui-video-card-skeleton__desc-1 app-skeleton-animated__panel" />
          <div class="ui-video-card-skeleton__desc-2 app-skeleton-animated__panel" />
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  strait: boolean
}

const propsDefault = withDefaults(defineProps<Props>(), { strait: false })
const {
  strait,
} = toRefs(propsDefault)
</script>

<style lang="scss">
.ui-video-card-skeleton {
  position: relative;
  display: flex;
  flex-direction: column;

  &__image {
    width: 100%;
    aspect-ratio: 381 / 214;
    background: map-get($grayPalette, 'color_28');
    border-radius: 10rem;
  }

  &__info {
    margin-top: 14rem;
    margin-bottom: 18rem;
    display: flex;
    gap: 0 14rem;
  }

  &__avatar {
    width: 44rem;
    height: 44rem;
    background: map-get($grayPalette, 'color_28');
    border-radius: 10rem;
  }

  &__content {
    width: calc(100% - 44rem - 14rem);
  }

  &__title-wrapper {
    display: flex;
    gap: 0 6rem;
  }

  &__title-1, &__title-2 {
    height: 14rem;
    border-radius: 17.5rem;
    background: map-get($grayPalette, 'color_28');
  }

  &__title-1 {
    width: 37.15%;
  }

  &__title-2 {
    width: 19.81%;
  }

  &__desc-1, &__desc-2 {
    height: 20rem;
    border-radius: 20rem;
    background: map-get($grayPalette, 'color_28');
  }

  &__desc-1 {
    margin-top: 16rem;
    width: 79.88%;
  }

  &__desc-2 {
    margin-top: 8rem;
    width: 43.34%;
  }
}

.ui-video-card-skeleton-strait {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 12rem;
  grid-template-areas:
    ". .";

  &__image {
    width: 100%;
    aspect-ratio: 162 / 91;
    background: map-get($grayPalette, 'color_28');
    border-radius: 10rem;
  }

  &__info {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__company {
    width: 57.97%;
    height: 14rem;
    border-radius: 17.5rem;
    background: map-get($grayPalette, 'color_28');
  }

  &__title {
    margin-top: 16rem;
    width: 100%;
    height: 20rem;
    border-radius: 20rem;
    background: map-get($grayPalette, 'color_28');
  }

  &__icons {
    margin-top: 8rem;
    width: 67.63%;
    height: 20rem;
    border-radius: 20rem;
    background: map-get($grayPalette, 'color_28');
  }
}
</style>
