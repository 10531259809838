import { defineStore, acceptHMRUpdate } from 'pinia'
import {
  type IApiContentSeoPagesItem,
  apiContentSeoPages,
} from '~/api/content/seo-page'
import type { NuxtApp } from '#app'

export const useSeoStore = defineStore('useSeoStore', {
  state() {
    return {
      pagesData: [] as IApiContentSeoPagesItem[],
      entity: {},
    }
  },
  getters: {},
  actions: {
    getPagesData(nuxtApp: NuxtApp) {
      return new Promise((resolve) => {
        nuxtApp.runWithContext(async () => {
          try {
            const { response } = await withCacheQuery('seoPages', apiContentSeoPages)
            if (Array.isArray(response.data.items) && response.data.items.length) {
              this.pagesData = response.data.items
            }
            resolve(response.data.items)
          } catch (e) {
            useConsole().warn('seoStore', 'getPagesData', e)
            resolve({})
          }

        })
      })
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSeoStore, import.meta.hot))
}
