/**
 * @method GET
 * @description Запросить dreamcamtrue user-info
 */

export interface IApiDreamcamtrueUserInfoProps {}

export interface IApiDreamcamtrueUserInfoData {
  user_name: string
  user_uuid: string
  dreamcam_token: string
}

export default async function apiDreamcamtrueUserInfo(payload: IApiDreamcamtrueUserInfoProps = {}) {
  return await useAsyncQuery<{ data: IApiDreamcamtrueUserInfoData }>(async ({ axios, pathUser }) => {
    const endpoint = '/dreamcam/v1/user-info'
    return await axios.get(pathUser + endpoint)
  })
}
