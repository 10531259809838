import { useUserStore } from '~/stores/user'
import { SOFT_PAGE_COOKIE_OPTIONS } from '~/shared/constants/soft-page'

export default defineNuxtRouteMiddleware((to) => {
  const seoRouteItemTypeEnum = {
    equal: 'eq',
    include: 'inc',
  }

  const seoRoutes = {
    'tag-slug': {
      param: 'slug',
      slug: [
        {
          type: seoRouteItemTypeEnum.equal,
          value: 'gay',
          route: { name: 'gay' },
        },
        {
          type: seoRouteItemTypeEnum.equal,
          value: 'trans',
          route: { name: 'trans' },
        },
        {
          type: seoRouteItemTypeEnum.equal,
          value: 'vr-games',
          route: { name: 'games' },
        },
      ],
    },
  }

  if (typeof to.name === 'string' && seoRoutes[to.name]) {
    // observe by param
    const param = seoRoutes[to.name]?.param
    if (param && seoRoutes[to.name][param]?.length) {
      for (const item of seoRoutes[to.name][param]) {
        if (item.type === seoRouteItemTypeEnum.equal) {
          if (item.value === to.params.slug) {
            return navigateTo(item.route, { redirectCode: 301 })
          }
        }
        if (item.type === seoRouteItemTypeEnum.include) {
          if (item.value.includes(to.params.slug)) {
            return navigateTo(item.route, { redirectCode: 301 })
          }
        }
      }
    }
  }


  const userStore = useUserStore()
  if (to?.name?.includes('account') && !userStore.atoken) {
    return navigateTo('/login', { redirectCode: 302 })
  }

  // Change email from url
  if (to?.fullPath.includes('/email-change/')) {
    // step 2 (form)
    if (to?.fullPath.includes('email-change-request')) {
      return navigateTo(`/account/setting?action=email-change-request&token=${to?.query?.token}`)
    }
    // step 3 (outro)
    if (to?.fullPath.includes('email-change-confirm')) {
      return navigateTo(`/account/setting?action=email-change-confirm&new_email=${to?.query['new-email']}&token=${to?.query?.token}`)
    }
  }

  // Request for personal data deletion
  if (to?.fullPath.includes('/account-removal/')) {
    return navigateTo(`/account/setting?action=account-removal&token=${to?.query?.rt}`)
  }

  // Search Tab
  if (to.name === 'search-tab') {
    // lower case
    if (to.query.query && String(to.query.query).toLowerCase() !== String(to.query.query)) {
      return navigateTo({
        name: 'search-tab',
        query: {
          ...to.query,
          query: String(to.query.query).toLowerCase(),
        },
      }, { redirectCode: 301 })
    }

    // query will be <= 67 symbols
    if (to.query.query && to.query.query.length >= 67) {
      return navigateTo({
        name: 'search-tab',
        query: {
          query: String(to.query.query).slice(0, 66)
        },
      }, { redirectCode: 301 })
    }
  }

  // SEO query redirect yes=all
  if (to.query?.all === 'yes') {
    const fullPath = to.fullPath
      .replace('?all=yes', '')
      .replace('&all=yes', '')
    return navigateTo(fullPath, { redirectCode: 301 })
  }

  // soft page activate logic
  const cookieSoftPage = useCookie<boolean>('soft-page', SOFT_PAGE_COOKIE_OPTIONS)
  if (to.fullPath.includes('/soft-page/on')) {
    cookieSoftPage.value = true
    return navigateTo('/', { redirectCode: 302 })
  }
  if (to.fullPath.includes('/soft-page/off')) {
    cookieSoftPage.value = false
    return navigateTo('/', { redirectCode: 302 })
  }
})
