import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/dark.css'

export default defineNuxtPlugin({
  name: 'app:socket',
  dependsOn: ['app:event-bus'],
  setup(nuxtApp) {
    return {
      provide: {
        datePickerConfig: {
          wrap: true,
          dateFormat: 'Y-m-d',
        },
      },
    }
  },
})
