import io from 'socket.io-client'
import { ref } from 'vue'
import { consola } from 'consola'
import { Socket } from 'socket.io-client/build/esm/socket'
import { useUserStore } from '~/stores/user'
import { useNotificationsStore } from '~/stores/notifications'

export default defineNuxtPlugin({
  name: 'app:socket',
  dependsOn: ['app:event-bus'],

  async setup({ $config }) {
    const nuxtApp = useNuxtApp()
    const config = $config

    const userStore = useUserStore()
    const isAuthorized = computed(() => userStore.isAuthorized)

    const notification = {
      socket: ref<Socket | null>(null),

      async run() {
        if (!config.public.SOCKET_NOTIFICATION_USE) {
          return config.public.APP_DEBUG && consola.info('[$io.notification]: off')
        }
        config.public.APP_DEBUG && consola.info('[$io.notification]: run')
        if (isAuthorized.value) {
          this.start()
        }

        watch(isAuthorized, (is) => {
          if (is) {
            this.start()
          } else {
            this.stop()
          }
        })

        return true
      },

      start() {
        this.connect()
      },
      stop() {
        this.disconnect()
      },

      connect() {
        config.public.APP_DEBUG && consola.info('[$io.notification]: connect')

        const hostname = config.public.SOCKET_NOTIFICATION_PROXY ? config.public.BASE_URL as string : config.public.SOCKET_NOTIFICATION_TARGET as string
        const path = config.public.SOCKET_NOTIFICATION_PROXY ? `/proxy-notification${config.public.SOCKET_NOTIFICATION_PATH}` : config.public.SOCKET_NOTIFICATION_PATH as string

        this.socket.value = io(hostname, {
          path,
          retries: 3,
          withCredentials: true,
          transports: ['websocket'],
          auth: {
            authorization: userStore.atoken,
          },
        })

        this.socket.value.on('notification', (event) => {
          this.onNotification(event)
        })
      },
      disconnect() {
        config.public.APP_DEBUG && consola.info('[$io.notification]: disconnect')
        if (this.socket.value) {
          this.socket.value.disconnect()
          this.socket.value = null
        }
      },
      reconnect() {
        this.disconnect()
        this.connect()
      },

      onNotification() {
        const notificationsStore = useNotificationsStore()
        notificationsStore.incrementUnreadCount()
        nuxtApp.$bus.emit('notifications:socket:new')
      },
    }

    /* --- --- dream-cams --- ---  */
    type ICamgirlsChatRunProps = {
      roomChatId: string
    }

    const camgirlsChat = {
      socket: ref<WebSocket | null>(),

      partnerId: ref<string>(config.public.REST_DREAMCAMTRUE_PARTNER_ID as string),

      clientId: generateUUID(),

      generateCorrelationId() {
        return 'adcf2100-0304-11ef-bd10-95c25345f662'
      },

      async run(payload: ICamgirlsChatRunProps) {
        config.public.APP_DEBUG && consola.info('[$io.camgirlsChat]: run')

        this.start(payload)
      },

      start(payload: ICamgirlsChatRunProps) {
        this.connect(payload)
      },
      stop() {
        this.disconnect()
      },

      connect(payload: ICamgirlsChatRunProps) {
        config.public.APP_DEBUG && consola.info('[$io.camgirlsChat]: connect')

        this.socket.value = new WebSocket(config.public.SOCKET_DREAMCAMTRUE_TARGET)

        const ping = {
          type: 'connectToRoomChat',
          correlationId: this.generateCorrelationId(),
          payload: {
            clientId: this.clientId,
            roomChatId: payload.roomChatId,
            partnerId: config.public.REST_DREAMCAMTRUE_PARTNER_ID,
          }
        }

        this.socket.value.addEventListener('open', (event) => {
          this.socket.value?.send(JSON.stringify(ping))
        })

        this.socket.value.addEventListener('message', (event) => {
          try {
            const data = JSON.parse(event.data)
            switch (data?.type) {
              case 'message':
              case 'tipsSent':
              case 'tipMenuItemPurchased':
              case 'toysMenuItemPurchased':
              case 'tipMenuPurchased':
                nuxtApp.$bus.emit('camgirls:socket:message', data)
                break
              case 'connectToRoomChat':
                nuxtApp.$bus.emit('camgirls:socket:connectToRoomChat', data?.payload?.success || false)
                break
              case 'broadcastViewersCount':
                nuxtApp.$bus.emit('camgirls:socket:broadcastViewersCount', data?.payload?.count || 0)
                break
              case 'clearChat':
                nuxtApp.$bus.emit('camgirls:socket:clearChat', data?.payload?.count || 0)
                break
              case 'deleteAllUserMessages':
                nuxtApp.$bus.emit('camgirls:socket:deleteAllUserMessages', data?.payload?.count || 0)
                break
              case 'historyMessage':
                nuxtApp.$bus.emit('camgirls:socket:historyMessage', data?.payload?.count || 0)
                break

            }
          } catch (e) {
            consola.error('[$io.camgirlsChat]: new message error:', e)
          }
        })
      },

      disconnect() {
        config.public.APP_DEBUG && consola.info('[$io.camgirlsChat]: disconnect')
        if (this.socket.value) {
          this.socket.value.close()
          this.socket.value = null
        }
      },
    }

    // run all socket
    await Promise.all([
      notification.run(),
    ])

    // close all socket
    window.addEventListener('beforeunload', () => {
      notification.disconnect()
    })

    return {
      provide: {
        io: {
          notification,
          camgirlsChat,
        },
      },
    }
  },
})
