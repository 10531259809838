/**
 * @method POST
 * @description регистрация
 * */

export interface IApiAuthRegisterProps {
  username: string
  email: string
  password: string
  email_marketing: boolean,
}

export default async function apiAuthRegister(payload: IApiAuthRegisterProps) {
  return await useAsyncQuery(async ({ axios, pathUser }) => {
    return await axios.post(pathUser + '/wp/auth/register', payload)
  })
}
