<template>
  <section v-if="ageCookie" id="scope-provider" class="provider-global-store" data-testid="elem_vrp_000889">
    <NuxtLoadingIndicator :throttle="0" :duration="4e3" color="repeating-linear-gradient(to right, #DD066D, #9000FF)" data-testid="elem_vrp_000890" />
  </section>
</template>

<script lang="ts" setup>
import { useCookie } from "#app"

const nuxtApp = useNuxtApp()

const router = useRouter()

const ageCookie = useCookie<boolean | null>('age', {
  default: () => null,
  path: '/',
  maxAge: 60 * 60 * 24 * 30,
  watch: true,
  domain: nuxtApp.$config.public.NUXT_COOKIE_DOMAIN,
})

prerenderRoutes(['/premiumlp/', '/buy-tokens/'])
</script>
