import { useReCaptcha, VueReCaptcha } from 'vue-recaptcha-v3'

export default defineNuxtPlugin({
  name: 'app:ReCaptcha:v3',
  dependsOn: ['app:event-bus'],
  setup(nuxtApp) {
    nuxtApp.vueApp.use(VueReCaptcha, {
      siteKey: '6LeBJEYqAAAAAIXGg-GTzcFZjiKuvkbRhMpg4CKs',
      autoHideBadge: true,
    })

    const captcha = useReCaptcha()
    setTimeout(async () => {
      await captcha?.recaptchaLoaded()
      captcha.instance.value.hideBadge()
    }, 0)
  },
})
