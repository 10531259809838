import {
  VRSMASH_DYNAMIC_REDIRECTS_REGEXP,
  VRSMASH_ENTITY_NAMES, VRSMASH_ROUTES,
  VRSMASH_STATIC_REDIRECTS,
} from "~/shared/constants/vrsmash-redirects";
import getSlugByEntityAndId from "~/api/content/postById";


export default defineNuxtRouteMiddleware(async (to) => {
  const { path, query, hash } = to

  if (VRSMASH_STATIC_REDIRECTS[path]) {
    return navigateTo({ path: VRSMASH_STATIC_REDIRECTS[path], query, hash }, { redirectCode: 301 })
  }

  if (path.match(/^\/compatible-vr-headsets.+/)) {
    return navigateTo({ path: '/how-to-watch-vr-porn-videos/', query, hash }, { redirectCode: 301 })
  }

  const matches = path.match(VRSMASH_DYNAMIC_REDIRECTS_REGEXP);
  const newPath = await getPath(matches)
  if (newPath) {
    return navigateTo({ path: newPath, query, hash }, { redirectCode: 301 })
  }

  if (path !== '/' && !path.includes('/proxy') && !path.endsWith('/') && path !== 'healthy') {
    const nextPath = path + '/' || '/'
    const nextRoute = { path: nextPath, query, hash }
    return navigateTo(nextRoute, { redirectCode: 301 })
  }
})

async function getPath(matches: Array<string>|null): string|null {
  if (!matches || !matches.length) {
    return null
  }

  const entity = VRSMASH_ENTITY_NAMES[matches[1]]
  const routeEntity = VRSMASH_ROUTES[matches[1]]
  const id = matches[2]
  const slugResponse = await getSlugByEntityAndId(entity, id)

  if (!slugResponse || slugResponse['$http'].status !== 200 || !slugResponse.data.item) {
    return '/404/'
  }

  return `/${routeEntity}/${slugResponse.data.item}/`
}
