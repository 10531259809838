import validate from "/var/www/vrp_content_front/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45seo_45redirects_45global from "/var/www/vrp_content_front/middleware/00.seoRedirects.global.ts";
import _01_45jshit_45global from "/var/www/vrp_content_front/middleware/01.jshit.global.ts";
import _02_45seo_45vrsmash_45redirects_45global from "/var/www/vrp_content_front/middleware/02.seoVrsmashRedirects.global.ts";
import _03_45verified_45member_45global from "/var/www/vrp_content_front/middleware/03.verifiedMember.global.ts";
import manifest_45route_45rule from "/var/www/vrp_content_front/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45seo_45redirects_45global,
  _01_45jshit_45global,
  _02_45seo_45vrsmash_45redirects_45global,
  _03_45verified_45member_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}