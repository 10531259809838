import Vue3Toastify, { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

export default defineNuxtPlugin({
  name: 'app:toast',

  setup() {
    const nuxtApp = useNuxtApp()
    nuxtApp.vueApp.use(Vue3Toastify, {
      limit: 3,
      autoClose: 2000,
      theme: 'dark',
      toastClassName: 'app-toast-item',
    })

    return {
      provide: { toast },
    }
  },
})
