import type { ISeoTemplateVariablesFlattenPartial, ISeoTemplateVariablesKeys } from '~/shared/types/composables/seo'
import type { IApiContentModelsItem } from '~/api/content/models'

export function getSeoAggregatesModel(
  context: ISeoTemplateVariablesFlattenPartial,
  variable: ISeoTemplateVariablesKeys,
  item: IApiContentModelsItem,
) {
  if ('_skeleton' in item) {
    return '_skeleton'
  }

  switch (variable) {
    case 'entity_name':
    case 'model_card_name':
      return item.name
    case 'model_card_address':
      if (item.gender ==='male') {
        return `${context.site_address}pornstars-male/${item.slug}/`
      }
      if (item.gender === 'trans') {
        return `${context.site_address}pornstars-trans/${item.slug}/`
      }
      if (item.gender === 'cgi') {
        return `${context.site_address}pornstars-cgi/${item.slug}/`
      }
      return `${context.site_address}pornstars/${item.slug}/`
    case 'model_preview_image_address':
      return item.image.path
    default:
      if (context[variable]) {
        return context[variable]
      }
      return `{var ${variable}: not define}`
  }
}
