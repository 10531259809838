/**
 * @method GET
 * @description получение seo настроек для страниц
 * */

import { SEO_ENTITY_PAGE_TYPE } from '~/shared/constants/seo'

export interface IApiContentSeoPageEntityItemProps {
  /** @description нужен для уточнения entity. используется с identifier */
  type: keyof typeof SEO_ENTITY_PAGE_TYPE
  /** @description если указан вернет entity-type-item, иначе entity-type */
  identifier: string | number
}

export interface IApiContentSeoPagesItem {
  id: string
  name: string
  title: string
  entityName: string
  metaDescription: string
  metaKeywords: string
  metaRobots: string
  heading: string
  canonical: string
  breadcrumb: string
  image?: {
    id: string
    width: string | number
    height: string | number
    alt: string
    path: string
  }
  isIncludeInSiteMap: boolean
  isJsonScriptLd: boolean
  contentJsonLd: string[]
  blocksJsonLd: string[]
  isOG: boolean
  ogParams: {
    type: string
    title: string
    description: string
    url: string
    siteName: string
    publishedTime?: string
    modifiedTime?: string
  }
  isTwitterParams: boolean
  twitterParams: {
    title: string
    card: string
    creator: string
    description: string
    domain: string
    site: string
  }
  createdAt: number
  updatedAt: number
}

export interface IApiContentSeoStatusResponse {
  code: number
  message: string
}

export interface IApiContentSeoPagesResponse {
  status: IApiContentSeoStatusResponse
  data: {
    items: IApiContentSeoPagesItem[]
  }
}

export interface IApiContentSeoPageEntityItemResponse {
  status: IApiContentSeoStatusResponse
  data: {
    item: IApiContentSeoPagesItem
  }
}

export async function apiContentSeoPages(): Promise<IApiContentSeoPagesResponse> {
  const endpoint = '/content/v1/seo/pages'
  return await useAsyncQuery(async ({ axios, path }) => {
    return await axios.get(path + endpoint)
  })
}

export async function apiContentSeoPageEntityItem(payload: IApiContentSeoPageEntityItemProps): Promise<IApiContentSeoPageEntityItemResponse> {
  const slug = SEO_ENTITY_PAGE_TYPE[payload.type]
  const endpoint = `/content/v1/seo/page/${slug}/${payload.identifier}`
  return await useAsyncQuery(async ({ axios, path }) => {
    return await axios.get(path + endpoint)
  })
}
