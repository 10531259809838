import { defineStore, acceptHMRUpdate } from 'pinia'
import { useNotificationsStore } from '~/stores/notifications'
import apiAuthLogin, { type IApiAuthLoginProps } from '~/api/auth/login'
import apiAuthRefresh from '~/api/auth/refresh'
import apiAuthRestorePassword, { type IApiAuthRestorePasswordProps } from '~/api/auth/restorePassword'
import apiAuthRegister, { type IApiAuthRegisterProps } from '~/api/auth/register'
import apiAuthResetPassword, { type IApiAuthResetPasswordProps } from '~/api/auth/resetPassword'
import apiAuthConfirmEmail, { type IApiAuthConfirmEmailProps } from '~/api/auth/confirmEmail'
import apiAuthUser, { type IApiAuthUserProps, type TAuthUserAuthorized } from '~/api/auth/user'
import apiAuthChangeName, { type IApiAuthChangeNameProps } from '~/api/auth/changeName'
import apiAuthConfirmChangeEmailAction, { type IApiAuthConfirmChangeEmailActionProps } from '~/api/auth/confirmChangeEmailAction'
import apiAuthInitAccountRemoval, { type IApiAuthInitAccountRemovalProps } from '~/api/auth/initAccountRemoval'
import apiAuthRemoveAccount, { type IApiAuthRemoveAccountProps } from '~/api/auth/removeAccount'
import apiAuthRequestChangeEmailAction, { type IApiAuthRequestChangeEmailActionProps } from '~/api/auth/requestChangeEmailAction'
import apiAuthConfirmEmailResend from '~/api/auth/confirmEmailResend'
import apiAuthSetNewPassword, { type IApiAuthSetNewPasswordProps } from '~/api/auth/setNewPassword'
import apiAuthRequestChangeEmailFromUrlAction from '~/api/auth/requestChangeEmailFromUrlAction'
import { useDreamcamAuthStore } from "~/stores/dreamcam/auth"

type TUserLogoutOptions = {
  disableRedirect: boolean
}

type TUserPayload = {
  /** @description true-domain */
  iss: string
  /** @description user uuid */
  jti: string
  /** @description issued at */
  iat: number
  /** @description not valid before */
  nbf: number
  /** @description expiration time */
  exp: number
  /** @description display name */
  udn: string
  /**
   * @description role
   * TODO: add support multi-roles
   * TODO: Read more of mtr in Google Docs "Role" (request access from TeamLead / QA)
   * */
  vra: 'guest' | 'user' | 'moderator' | 'admin' | 'super_admin' | 'studio_owner',
  /** @description status */
  usc: 1 | 100 | 200 | 300
  /** @description Read more of mtr in Google Docs "Role" (request access from TeamLead / QA) */
  mtr: boolean
  /** @description email */
  ue: string
  /** @description use only in account-setting page */
  passwordChangedAt?: {
    date: string
  }
  /** @description now not used */
  partners: [],
} | null

export const atokenStoreKey = 'atoken'
export const rtokenStoreKey = 'rtoken'

function createEmptyUser(): TUserPayload {
  return null
}

export const tokenStoreOptions = {
  default: () => null,
  path: '/',
  maxAge: 60 * 60 * 24 * 30,
}

export const useUserStore = defineStore('userStore', {
  state() {
    return {
      atoken: '',
      rtoken: '',
      affiliateId: '',
      jshit: '',
      user: createEmptyUser(),
      needEmailConfirm: false,
      roles: {
        guest: 'guest',
        user: 'user',
        moderator: 'moderator',
        admin: 'admin',
        superAdmin: 'super_admin',
        studioOwner: 'studio_owner',
      },
      statuses: {
        inactive: 1,
        active: 100,
        canceled: 200,
        expired: 300,
      },
    }
  },
  getters: {
    isAuthorized(state) {
      return Boolean(state.atoken) && Boolean(state.user?.ue)
    },
    isStatus(state) {
      type TKeys = keyof typeof state.statuses
      type TValues = { [key in keyof typeof state.statuses]: boolean }
      return Object.keys(state.statuses).reduce((accumulator, _key) => {
        const key = _key as TKeys
        if (!this.isAuthorized && key === 'inactive') {
          accumulator[key] = true
          return accumulator
        }
        accumulator[key] = state.statuses[key] === (state.user?.usc || 1)
        return accumulator
      }, {} as TValues)
    },
    isRole(state) {
      type TKeys = keyof typeof state.roles
      type TValues = { [key in keyof typeof state.roles]: boolean }
      return Object.keys(state.roles).reduce((accumulator, _key) => {
        const key = _key as TKeys
        if (!this.isAuthorized && key === 'guest') {
          accumulator[key] = true
          return accumulator
        }

        accumulator[key] = state.roles[key] === (state.user?.vra || 'guest')
        if([100, 200].includes(state.user?.usc)) {
          accumulator['user'] = true
          accumulator['guest'] = false
        }
        return accumulator
      }, {} as TValues)
    },
    maskEmail() {
      return (email: string) => {
        if (!email || !email.includes('@')) {
          return email
        }

        const [localPart, domain] = email.split('@')
        const domainParts = domain.split('.')

        const minCharsToHide = Math.ceil(localPart.length * 0.3)
        const domainCharsToHide = Math.ceil(domainParts[0].length * 0.3)

        const hiddenLocalPart = localPart.slice(0, -minCharsToHide).padEnd(localPart.length, '*')
        const hiddenDomain = domainParts.map((part, index) => {
          if (!index) {
            return part.slice(0, -domainCharsToHide).padEnd(part.length, '*')
          }
          return part
        }).join('.')

        return `${hiddenLocalPart}@${hiddenDomain}`
      }
    },
  },
  actions: {
    getTokenStoreOptions() {
      const nuxtApp = useNuxtApp()

      return {
        ...tokenStoreOptions,
        domain: nuxtApp.$config.public.NUXT_COOKIE_DOMAIN,
      }
    },

    setTokens(atoken: string, rtoken?: string, forceSetRtoken = false) {
      const atokenCookie = useCookie<string | null>(atokenStoreKey, this.getTokenStoreOptions())
      const rtokenCookie = useCookie<string | null>(rtokenStoreKey, this.getTokenStoreOptions())

      atokenCookie.value = atoken
      this.atoken = atoken

      if (rtoken || forceSetRtoken) {
        rtokenCookie.value = rtoken || ''
        this.rtoken = rtoken || ''
      }

      if (!atokenCookie.value) {
        atokenCookie.value = null
      }
      if (!rtokenCookie.value) {
        rtokenCookie.value = null
      }
    },
    getTokens() {
      const atokenCookie = useCookie<string | null>(atokenStoreKey, this.getTokenStoreOptions())
      const rtokenCookie = useCookie<string | null>(rtokenStoreKey, this.getTokenStoreOptions())

      const atoken = atokenCookie.value || ''
      const rtoken = rtokenCookie.value || ''

      this.atoken = atoken
      this.rtoken = rtoken

      return {
        atoken,
        rtoken,
      }
    },

    decodeJWT(jwt: string) {
      if (!jwt.split) return
      const base64Url = jwt.split('.')?.[1]
      if (!base64Url?.length) return
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      if (!base64?.length) return
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
      return JSON.parse(jsonPayload)
    },

    async authorize(jwt: string, rtoken = '') {
      const payload = this.decodeJWT(jwt)
      if (!payload) {
        return false
      }

      this.setTokens(jwt, rtoken)
      this.user = payload

      const dreamcamAuthStore = useDreamcamAuthStore()
      const notificationsStore = useNotificationsStore()
      await notificationsStore.checkIfUnread()

      if (import.meta.client) {
        try {
          dreamcamAuthStore.login()
        } catch (e) {
          useConsole().warn('dreamcamAuthStore', 'login', e)
        }
      }
    },

    async restoreSession() {
      const { atoken, rtoken } = this.getTokens()
      if (atoken && rtoken) {
        await this.authorize(atoken, rtoken)
      }
    },

    async refresh() {
      try {
        const { rtoken } = this.getTokens()
        const { data: { token } } = await apiAuthRefresh({ refresh_token: rtoken })
        if (token.atoken && token.rtoken) {
          this.setTokens(token.atoken, token.rtoken)
          await this.authorize(token.atoken, token.rtoken)
        }
      } catch (error) {
        await this.logout()
      }
    },

    async getInfoAboutMe(payload: IApiAuthUserProps = {}, needUpdate = true) {
      try {
        const response = await apiAuthUser(payload)
        if (response.status.code === 1) {
          if (needUpdate) {
            this.updateInfoAboutMe(response.data)
          }
          return response.data as TAuthUserAuthorized['data']
        }

        return response.status
      } catch (e) {
        useConsole().warn('[useUserStore]', 'getInfoAboutMe', e)
      }
    },

    updateInfoAboutMe(info: TAuthUserAuthorized['data']) {
      if (this.user) {
        this.user.jti = info.item.uuid
        this.user.udn = info.item.name
        this.user.ue = info.item.email
        this.user.passwordChangedAt = info.item.passwordChangedAt
      }
    },

    setAffiliateId (value: string) {
      this.affiliateId = value
    },

    getAffiliateId () {
      return this.affiliateId
    },

    setJsHit (value: string) {
      this.jshit = value
    },

    getJsHit () {
      return this.jshit
    },

    async login(payload: IApiAuthLoginProps) {
      const auth = await apiAuthLogin(payload)

      // check need email confirm
      if (auth?.$http?.status === 403) {
        this.user = {
          ue: payload.email,
        }
        this.needEmailConfirm = true
        return auth
      }

      // authorize
      if (auth?.data?.token?.atoken && auth?.data?.token?.rtoken) {
        await this.authorize(auth.data?.token.atoken, auth.data.token.rtoken)
        return this.user
      }

      return auth
    },
    async register(payload: IApiAuthRegisterProps) {
      const auth = await apiAuthRegister(payload)
      if (auth?.data?.uuid) {
        this.user = {
          ue: payload.email
        }
        this.needEmailConfirm = true
        return auth
      }
      return auth
    },
    async logout(options?: TUserLogoutOptions) {
      this.setTokens('', '', true)
      this.user = createEmptyUser()

      const router = useRouter()
      if (!options?.disableRedirect) {
        router.push({ name: 'index' })
      }
    },

    async confirmEmail(payload: IApiAuthConfirmEmailProps) {
      const response = await apiAuthConfirmEmail(payload)
      if (response.data?.token?.atoken && response.data?.token?.rtoken) {
        await this.authorize(response.data.token.atoken, response.data.token.rtoken)
        return true
      }
      return false
    },

    async confirmEmailResend() {
      if (this.isAuthorized || this.user?.ue) {
        return await apiAuthConfirmEmailResend({
          email: this.user.ue
        })
      }
    },

    async restorePassword(payload: IApiAuthRestorePasswordProps) {
      return await apiAuthRestorePassword(payload)
    },

    async resetPassword(payload: IApiAuthResetPasswordProps) {
      return await apiAuthResetPassword(payload)
    },

    async changeName(payload: IApiAuthChangeNameProps) {
      return await apiAuthChangeName(payload)
    },

    async setNewPassword(payload: IApiAuthSetNewPasswordProps) {
      return await apiAuthSetNewPassword(payload)
    },

    async requestChangeEmailFromUrlAction() {
      return await apiAuthRequestChangeEmailFromUrlAction()
    },

    async requestChangeEmailAction(payload: IApiAuthRequestChangeEmailActionProps) {
      return await apiAuthRequestChangeEmailAction(payload)
    },

    async confirmChangeEmailAction(payload: IApiAuthConfirmChangeEmailActionProps) {
      return await apiAuthConfirmChangeEmailAction(payload)
    },

    async initAccountRemoval(payload: IApiAuthInitAccountRemovalProps) {
      return await apiAuthInitAccountRemoval(payload)
    },

    async removeAccount(payload: IApiAuthRemoveAccountProps) {
      return await apiAuthRemoveAccount(payload)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
